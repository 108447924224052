import React, { useState, useEffect } from "react";
import axios from "axios";
import "./QuotationData.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const QuotationData = () => {
  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItem, setSelectedItem] = useState(null); // To store the selected item to show details

  useEffect(() => {
    // Fetch data from the server when the component mounts
    axios
      .get("https://incronix.com/config2.php")
      .then((response) => {
        const sorted = response.data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        ); // Sort data by date in descending order
        setData(sorted.map((item) => ({ ...item, showDetails: false })));
        setSortedData(sorted);
      })
      .catch((error) => {
        console.error("Error fetching quotation data:", error);
      });
  }, []);

  const sortByName = () => {
    const sorted = [...sortedData].sort((a, b) => {
      // Check if both names are not null before calling localeCompare
      if (a.name && b.name) {
        return a.name.localeCompare(b.name);
      } else {
        // If either name is null, return 0 to maintain original order
        return 0;
      }
    });
    setSortedData(sorted);
  };

  const toggleDetails = (index) => {
    setData(
      data.map((item, i) =>
        i === index ? { ...item, showDetails: !item.showDetails } : item
      )
    );
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase(); // Convert search term to lowercase
    setSearchTerm(value);

    // Filter data based on search term
    const filteredData = data.filter((item) => {
      // Check if item.name is not null or undefined before calling toLowerCase()
      return item.name && item.name.toLowerCase().includes(value);
    });

    setSortedData(filteredData);
  };

  const openDetailsModal = (item) => {
    setSelectedItem(item);
  };

  const closeDetailsModal = () => {
    setSelectedItem(null);
  };

  const deleteRecord = (name) => {
    // Send DELETE request to the server with the record name
    axios
      .delete(`https://incronix.com/config2.php`, {
        data: { name: name },
      })
      .then((response) => {
        // After successful deletion, fetch updated data
        axios
          .get("https://incronix.com/config2.php")
          .then((response) => {
            const sorted = response.data.sort(
              (a, b) => new Date(b.date) - new Date(a.date)
            ); // Sort data by date in descending order
            setData(sorted.map((item) => ({ ...item, showDetails: false })));
            setSortedData(sorted);
          })
          .catch((error) => {
            console.error("Error fetching quotation data:", error);
          });
      })
      .catch((error) => {
        console.error("Error deleting record:", error);
      });
  };

  return (
    <div className="page-container">
      <div className="quotation-container">
        <h2>Quotation Data</h2>
        <div>
          <input
            type="text"
            placeholder="Search by Name"
            value={searchTerm}
            onChange={handleSearch}
          />
          <button onClick={sortByName}>Sort by Name</button>
        </div>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Message</th>
                <th>Date</th>
                <th>View</th>
                <th>Delete</th> {/* Add Delete column */}
              </tr>
            </thead>
            <tbody>
              {sortedData.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.message}</td>
                    <td>{item.date}</td>
                    <td>
                      <button onClick={() => openDetailsModal(item)}>
                        View
                      </button>
                    </td>
                    <td>
                      <button onClick={() => deleteRecord(item.name)}>
                        <FontAwesomeIcon icon={faTrash} color="red" />
                      </button>
                    </td>{" "}
                    {/* Delete button */}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Modal for displaying details */}
      {selectedItem && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeDetailsModal}>
              &times;
            </span>
            <h2>Details</h2>
            <table>
              <tbody>
                <tr>
                  <th>Options</th>
                  <th>Selected Options</th>
                  <th>Amount</th>
                </tr>
                <tr>
                  <td>Platforms:</td>
                  <td>{selectedItem.selectedOptions.platforms}</td>
                  <td>{selectedItem.pamount}</td>
                </tr>
                <tr>
                  <td>Web Type:</td>
                  <td>{selectedItem.selectedOptions.webtype}</td>
                  <td>{selectedItem.wamount}</td>
                </tr>
                <tr>
                  <td>Pages:</td>
                  <td>{selectedItem.selectedOptions.pagesf}</td>
                  <td>{selectedItem.ppamount}</td>
                </tr>
                <tr>
                  <td>Pages Name:</td>
                  <td>{selectedItem.selectedOptions.pagesName}</td>
                  <td>{selectedItem.namount}</td>
                </tr>
                <tr>
                  <td>Login Option:</td>
                  <td>{selectedItem.selectedOptions.loginOption}</td>
                  <td>{selectedItem.lamount}</td>
                </tr>
                <tr>
                  <td>Features:</td>
                  <td>{selectedItem.selectedOptions.features}</td>
                  <td>{selectedItem.famount}</td>
                </tr>
                <tr>
                  <td>Admin:</td>
                  <td>{selectedItem.selectedOptions.admin1}</td>
                  <td>{selectedItem.aamount}</td>
                </tr>
                <tr>
                  <td>Design:</td>
                  <td>{selectedItem.selectedOptions.designl}</td>
                  <td>{selectedItem.damount}</td>
                </tr>
                <tr>
                  <td>Total Amount:</td>
                  <td></td>
                  <td>{selectedItem.tamount}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuotationData;
