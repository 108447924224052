import React, { cloneElement, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";

const Service = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 120,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <>
      <link href="assets/bootstrap/bootstrap.min.css" rel="stylesheet" />
      <link rel="stylesheet" href="./assets/js/bootstrap.min.js" />

      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
      />

      <link href="assets/css/style.css" rel="stylesheet" type="text/css" />
      <link
        href="assets/css/mediaqueries.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="assets/css/owl.carousel.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="assets/css/owl.theme.default.min.css"
        rel="stylesheet"
        type="text/css"
      />

      <div className="sub-banner-section">
        <Header />
        {/* SUB BANNER SECTION */}

        <section className="banner-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-md-left text-center">
                <div className="banner-section-content">
                  <h1 data-aos="fade-up">Our Services</h1>

                  <p data-aos="fade-right">
                    Discover unparalleled solutions tailored to your needs at
                    INCRONIX. Elevate your business with our expertly crafted
                    services and dedicated support.
                  </p>
                  <div className="btn_wrapper">
                    <span>Home </span>
                    <i
                      className="fa-solid fa-angles-right"
                      aria-hidden="true"
                    />
                    <span className="sub_span"> Services</span>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                data-aos="fade-left"
              >
                <div className="banner-section-image">
                  <figure className="mb-0">
                    <img
                      src="./assets/images/biglogo6.webp"
                      alt=""
                      className="img-fluid"
                      style={{
                        marginTop: "-20px",
                        maxWidth: "100%",
                        paddingLeft: "auto",
                        justifyContent: "center",
                      }}
                    />
                  </figure>
                </div>
              </div>
            </div>
            <figure className="sub-banner_yellow_icon mb-0">
              <img
                src="./assets/images/sub_banner_yellow_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_va_icon mb-0">
              <img
                src="./assets/images/sub_banner_va_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_bottom_white_icon mb-0">
              <img
                src="./assets/images/sub_banner_bottom_white_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_right_top_icon mb-0">
              <img
                src="./assets/images/sub_banner_right_top_transparent_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_right_bottom_icon mb-0">
              <img
                src="./assets/images/sub_banner_right_bottom_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
          </div>
        </section>
      </div>
      {/* RESEARCH & REPORTS SECTION */}
      <section className="research_reports-section">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div>
                <figure className="mb-0 d-flex justify-content-center">
                  <img
                    src="./assets/images/localapplication1.jpeg"
                    alt=""
                    style={{
                      maxWidth: "100%", // Set maximum width to 100%
                      height: "auto", // Maintain aspect ratio
                      borderRadius: "40px",
                      marginTop: "20px",
                      marginLeft: "15px",
                    }}
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
              <div className="research_reports_content">
                <h2>Local Application</h2>
                <p>
                  Optimize efficiency with tailored Local Applications. Elevate
                  workflow with seamless user experiences. Enhance business
                  capabilities with cutting-edge reliability
                </p>
                <ul className="list-unstyled">
                  <li>
                    <i className="fa fa-check" aria-hidden="true" />
                    Seamless user experiences to elevate workflow
                  </li>
                  <li>
                    <i className="fa fa-check" aria-hidden="true" />
                    Enhancing business capabilities through innovative
                    applications
                  </li>
                </ul>
                <div className="btn_wrapper"></div>
              </div>
            </div>
            <figure className="research_reports_shape mb-0">
              <img
                src="./assets/images/research_reports_shape.png"
                alt=""
                className="img-fluid"
                data-aos="fade-up"
              />
            </figure>
          </div>
        </div>
      </section>
      {/* EXPENSING & INVOICING SECTION */}
      <section className="expense_invoice-section">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="research_reports_content">
                <h2>Web Services</h2>
                <p>
                  Empower your online presence with dynamic Web Services. Drive
                  success with sophisticated solutions. Optimize your digital
                  strategy for seamless experiences.
                </p>
                <ul className="list-unstyled">
                  <li>
                    <i className="fa fa-check" aria-hidden="true" />
                    Empower your online presence
                  </li>
                  <li>
                    <i className="fa fa-check" aria-hidden="true" />
                    Optimize digital strategy
                  </li>
                </ul>
                <div className="btn_wrapper"></div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div>
                <figure className="mb-0 d-flex justify-content-center">
                  <img
                    src="./assets/images/webservice5.jpeg"
                    alt=""
                    style={{
                      maxWidth: "100%", // Set maximum width to 100%
                      height: "auto", // Maintain aspect ratio
                      borderRadius: 40,
                      marginTop: 20,
                      marginLeft: "15px",
                    }}
                  />
                </figure>
              </div>
            </div>
          </div>
          <figure className="expense_invoice_left_icon mb-0">
            <img
              src="./assets/images/expense_invoice_left_icon.png"
              alt=""
              className="img-fluid"
            />
          </figure>
          <figure className="expense_invoice_right_icon mb-0">
            <img
              src="./assets/images/expense_invoice_right_icon.png"
              alt=""
              className="img-fluid"
            />
          </figure>
        </div>
      </section>
      {/* SCHEDULING & PLANNING SECTION */}
      <section className="schedule_plan-section">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div>
                <figure className="mb-0 d-flex justify-content-center">
                  <img
                    src="./assets/images/native1.jpeg"
                    alt=""
                    style={{
                      maxWidth: "100%", // Set maximum width to 100%
                      height: "auto", // Maintain aspect ratio
                      borderRadius: 40,
                      marginTop: 20,
                      marginLeft: "15px",
                    }}
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
              <div className="research_reports_content">
                <h2>Native Apps</h2>
                <p>
                  Experience exceptional Native Apps merging functionality with
                  aesthetics. Unlock mobile potential with optimal performance
                  and user-centric design. Embrace mobile excellence seamlessly
                  blending innovation at your fingertips.
                </p>
                <ul className="list-unstyled">
                  <li>
                    <i className="fa fa-check" aria-hidden="true" />
                    Exceptional user immersion with our Native Apps
                  </li>
                  <li>
                    <i className="fa fa-check" aria-hidden="true" />
                    Seamless blending of innovation and precision
                  </li>
                </ul>
              </div>
            </div>
            <figure className="research_reports_shape mb-0">
              <img
                src="./assets/images/research_reports_shape.png"
                alt=""
                className="img-fluid"
                data-aos="fade-up"
              />
            </figure>
          </div>
        </div>
      </section>

      {/* FOOTER SECTION */}
      <Footer />

      {/* Latest compiled JavaScript */}
    </>
  );
};
export default Service;
