import React, { useRef } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

const Career_mail = () => {
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Retrieve form data
    const formData = new FormData(form.current);

    const jsonData = {};
    formData.forEach((value, key) => {
      jsonData[key] = value;
    });

    // Send POST request to server with form data
    axios
      .post("https://incronix.com/career.php", jsonData)
      .then((response) => {
        alert("Thankyou!, Our team will contact you soon...");

        form.current.reset(); // Reset the form after successful submission
      })
      .catch((error) => {
        console.error("Error submitting details:", error);
      });
  };

  return (
    <section className="send_message_section">
      <div className="container">
        <div className="row" data-aos="fade-up">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="in_touch-content" style={{ textAlign: "center" }}>
              <h6>JOIN US</h6>
              <h2>Discover Rewarding Opportunities</h2>
              <p>
                Discover rewarding career opportunities at INCRONIX where
                innovation meets excellence. Join our dynamic team and
                contribute to impactful projects that drive success. Explore
                diverse roles and grow your career with us. Your journey to
                professional fulfillment starts here.
              </p>
            </div>
          </div>

          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
            <div className="send_message_box_content">
              <h6>READY TO</h6>
              <h2>Get Started With Us</h2>

              <h6>
                Join us and work on cutting-edge projects <br />
                with a team that's as passionate as you are
                <br />
                <br />
                <br />
                <br />
              </h6>
            </div>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
            <div className="send_message_form_box_content">
              <h6>Joining Form</h6>
              <form ref={form} onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form_style"
                        placeholder="Name"
                        name="name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group mb-0">
                      <input
                        type="email"
                        className="form_style"
                        placeholder="Email"
                        name="mail"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group mb-0">
                      <input
                        type="tel"
                        className="form_style"
                        placeholder="Phone"
                        name="phone"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form_style mt-0"
                        placeholder="Job Role"
                        name="role"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mb-0">
                      <textarea
                        className="form_style"
                        placeholder="Why should we hire you?"
                        rows={3}
                        name="message"
                        defaultValue={""}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="form_button_wrapper text-center">
                  <button type="submit" className="appointment-btn">
                    Submit Now
                  </button>
                </div>
              </form>
            </div>
            <figure className="send_message_yellow_icon mb-0">
              <img
                src="./assets/images/send_message_yellow_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
          </div>
        </div>
        <figure className="send_message_left_icon mb-0">
          <img
            src="./assets/images/send_message_left_icon.png"
            alt=""
            className="img-fluid"
          />
        </figure>
        <figure className="send_message_right_icon mb-0">
          <img
            src="./assets/images/send_message_right_icon.png"
            alt=""
            className="img-fluid"
          />
        </figure>
        <figure className="send_message_bottom_icon mb-0">
          <img
            src="./assets/images/send_message_bottom_icon.png"
            alt=""
            className="img-fluid"
          />
        </figure>
      </div>
    </section>
  );
};

export default Career_mail;
