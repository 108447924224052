import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Service from "./Pages/Service";
import Contact from "./Pages/Contact";
import Career from "./Pages/Career";
import Project from "./Pages/Project";
import Error404 from "./Pages/Error404";
import CareerDB from "./Pages/CareerDB";
import ContactDB from "./Pages/ContactDB";
import AdminPanel from "./Pages/AdminPanel";
import AdminSections from "./Pages/AdminSections";
import Calculation from "./Pages/Calculation";
import QuotationData from "./Pages/QuotationData";
import Certificate from "./Certificates/Certificate";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/project" element={<Project />} />
        <Route path="/career" element={<Career />} />
        <Route path="*" element={<Error404 />} />
        <Route path="/careerDB" element={<CareerDB />} />
        <Route path="/contactDB" element={<ContactDB />} />
        <Route path="/admin-panel" element={<AdminPanel />} />
        <Route path="/AdminSections" element={<AdminSections />} />
        <Route path="/calculation" element={<Calculation />} />
        <Route path="/QuotationData" element={<QuotationData />} />
        <Route path="/certificates/:id" element={<Certificate />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
