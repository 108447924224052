import React, { cloneElement, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "../Pages/style.css";

const AdminPanel = () => {
  const formRef = useRef();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 120,
      easing: "ease-in-out",
    });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(formRef.current);
    const enteredUsername = formData.get("admin");
    const enteredPassword = formData.get("password");

    // Check if entered username and password match the fixed values
    if (
      enteredUsername === "Admin_Incronix" &&
      enteredPassword === "Admin@Incronix"
    ) {
      // Redirect to AdminSections page upon successful login
      window.location.href = "/AdminSections";
    } else {
      // Invalid credentials, show alert
      alert("Wrong password. Please try again.");
    }
  };
  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
      />
      <meta charSet="utf-8" />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href="./assets/images/favicon/apple-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="./assets/images/favicon/apple-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="./assets/images/favicon/apple-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="./assets/images/favicon/apple-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="./assets/images/favicon/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="./assets/images/favicon/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="./assets/images/favicon/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="./assets/images/favicon/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="./assets/images/favicon/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="./assets/images/favicon/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="./assets/images/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="./assets/images/favicon/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="./assets/images/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="./assets/images/favicon/manifest.json" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      {/* Latest compiled and minified CSS */}
      <link href="assets/bootstrap/bootstrap.min.css" rel="stylesheet" />
      <link rel="stylesheet" href="./assets/js/bootstrap.min.js" />
      {/* Font Awesome link */}
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
      />
      {/* StyleSheet link CSS */}
      <link href="assets/css/style.css" rel="stylesheet" type="text/css" />
      <link
        href="assets/css/mediaqueries.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="assets/css/owl.carousel.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="assets/css/owl.theme.default.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <div className="sub-banner-section">
        <div class="banner-section-outer">
          <header>
            <div className="main_header">
              <div className="container-fluid">
                <nav className="navbar navbar-expand-lg navbar-light p-0 phone">
                  <Link to="/">
                    <figure className="mb-0 image">
                      <img
                        src="./assets/images/logo1.png"
                        alt=""
                        className="img-fluid"
                        style={{ width: 150 }}
                      />
                    </figure>
                  </Link>
                  <button
                    className="navbar-toggler collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    style={{ marginRight: "50px" }}
                  >
                    <span className="navbar-toggler-icon" />
                    <span className="navbar-toggler-icon" />
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className="collapse navbar-collapse menu"
                    style={{ padding: "20px 0px" }}
                    id="navbarSupportedContent"
                  >
                    <ul
                      className="navbar-nav options"
                      style={{ marginRight: "0px" }}
                    >
                      <li className="nav-item active option">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="nav-item option">
                        <Link to="/service">Service</Link>
                      </li>
                      <li className="nav-item option">
                        <Link to="/project">Portfolio</Link>
                      </li>
                      <li className="nav-item option">
                        <Link to="/about">About</Link>
                      </li>
                      <li className="nav-item option">
                        <Link to="/career">Career</Link>
                      </li>
                      {/* <li className="nav-item">
        <Link to="/contact">Contact</Link>
      </li> */}
                    </ul>
                    {/* You can add other menu elements here */}
                  </div>
                </nav>
              </div>
            </div>
          </header>
          <section className="banner-section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-md-left text-center">
                  <div className="banner-section-content">
                    <h1 className="space" style={{ fontSize: "70px" }}>
                      Admin
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="banner-section-image">
                    <figure className="mb-0">
                      <img
                        src="./assets/images/biglogo6.png"
                        alt=""
                        className="img-fluid"
                        style={{
                          marginTop: "-20px",
                          marginLeft: "auto",
                          maxWidth: "100%",
                        }}
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <figure className="sub-banner_yellow_icon mb-0">
                <img
                  src="./assets/images/sub_banner_yellow_icon.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
              <figure className="sub-banner_va_icon mb-0">
                <img
                  src="./assets/images/sub_banner_va_icon.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
              <figure className="sub-banner_bottom_white_icon mb-0">
                <img
                  src="./assets/images/sub_banner_bottom_white_icon.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>

              <figure className="sub-banner_right_top_icon mb-0">
                <img
                  src="./assets/images/sub_banner_right_top_transparent_icon.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
              <figure className="sub-banner_right_bottom_icon mb-0">
                <img
                  src="./assets/images/sub_banner_right_bottom_icon.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
            </div>
          </section>
        </div>
      </div>
      <div></div>
      <section className="send_message_section">
        <div className="container">
          <div
            className="row"
            data-aos="fade-up"
            style={{ justifyContent: "center" }}
          >
            <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center">
              <div className="send_message_form_box_content">
                <h6>Admin Login</h6>
                <form ref={formRef} onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          className="form_style"
                          placeholder="Enter Admin Name"
                          name="admin"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-0">
                        <input
                          type="password"
                          className="form_style"
                          placeholder="Admin Password"
                          name="password"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form_button_wrapper text-center">
                    <button type="submit" className="appointment-btn">
                      Submit Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br></br>
      <br></br>
      {/* FOOTER SECTION */}
      <section className="footer-section">
        <div className="container">
          <div className="middle-portion">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 d-lg-block d-none" />
              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <div className="about_col">
                  <h4>Social Media</h4>
                  <ul className="list-unstyled">
                    <li>
                      <p className="pp">
                        Connect with us on social media for updates
                      </p>
                    </li>
                    <ul
                      className="icons"
                      style={{ listStyle: "none", padding: 0 }}
                    >
                      <li
                        className="icons"
                        style={{
                          display: "inline-block",
                          marginRight: "10px",
                        }}
                      >
                        <a href="https://www.instagram.com/incronix_technology/?hl=en">
                          <i
                            className="fa-brands fa-instagram"
                            aria-hidden="true"
                          />
                        </a>
                      </li>
                      <li
                        className="icons"
                        style={{
                          display: "inline-block",
                          marginRight: "10px",
                        }}
                      ></li>
                      <li
                        className="icons"
                        style={{
                          display: "inline-block",
                          marginRight: "10px",
                        }}
                      >
                        <a href="https://www.linkedin.com/company/incronix-technology/">
                          <i
                            className="fa-brands fa-linkedin-in"
                            aria-hidden="true"
                          />
                        </a>
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-3 col-xs-12 d-md-block d-none">
                <div className="links_col">
                  <h4>Useful Links</h4>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/service">Service</Link>
                    </li>
                    <li>
                      <Link to="/career">Career</Link>
                    </li>
                    <li>
                      <Link to="/project">Portfolio</Link>
                    </li>
                    {/* <li>
                      <Link to="/contact">Contact</Link>
                    </li> */}
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div className="contact_col">
                  <h4>Contact Info</h4>
                  <ul className="list-unstyled">
                    <li>
                      <i className="fa-sharp fa-solid fa-envelope" />
                      <a
                        href="mailto:Info@instantva.om"
                        className="text-decoration-none"
                      >
                        hr@incronix.com
                      </a>
                    </li>
                    <li className="mb-0">
                      <i className="fa-solid fa-location-dot location" />
                      <a className="text-decoration-none">
                        Aundh, Pune -411007
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-portion">
          <div className="copyright col-xl-12">
            <p style={{ fontSize: "15px" }}>
              © 2024 INCRONIX TECHNOLOGY, All rights reserved.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminPanel;
