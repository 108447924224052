import React, { cloneElement, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Product.css";
import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";

const Project = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 120,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
      />
      <meta charSet="utf-8" />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href="./assets/images/favicon/apple-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="./assets/images/favicon/apple-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="./assets/images/favicon/apple-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="./assets/images/favicon/apple-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="./assets/images/favicon/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="./assets/images/favicon/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="./assets/images/favicon/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="./assets/images/favicon/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="./assets/images/favicon/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="./assets/images/favicon/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="./assets/images/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="./assets/images/favicon/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="./assets/images/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="./assets/images/favicon/manifest.json" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      {/* Latest compiled and minified CSS */}
      <link href="assets/bootstrap/bootstrap.min.css" rel="stylesheet" />
      <link rel="stylesheet" href="./assets/js/bootstrap.min.js" />
      {/* Font Awesome link */}
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
      />
      {/* StyleSheet link CSS */}
      <link href="assets/css/style.css" rel="stylesheet" type="text/css" />
      <link
        href="assets/css/mediaqueries.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="assets/css/owl.carousel.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="assets/css/owl.theme.default.min.css"
        rel="stylesheet"
        type="text/css"
      />

      <div className="sub-banner-section">
        <Header />
        {/* SUB BANNER SECTION */}
        <section className="banner-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-md-left text-center">
                <div className="banner-section-content">
                  <h1 data-aos="fade-up">Portfolio</h1>
                  <p data-aos="fade-right">
                    Building success is a journey, not a destination. INCRONIX
                    becomes a trusted partner for our clients, fostering
                    relationships built on collaboration and exceeding
                    expectations. We're invested in your success, every step of
                    the way.
                  </p>
                  <div className="btn_wrapper">
                    <span>Home </span>
                    <i
                      className="fa-solid fa-angles-right"
                      aria-hidden="true"
                    />
                    <span className="sub_span"> Portfolio</span>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                data-aos="fade-left"
              >
                <div className="banner-section-image">
                  <figure className="mb-0">
                    <img
                      src="./assets/images/biglogo6.webp"
                      alt=""
                      className="img-fluid"
                      style={{
                        marginTop: "-20px",
                        marginLeft: "auto",
                        maxWidth: "100%",
                      }}
                    />
                  </figure>
                </div>
              </div>
            </div>
            <figure className="sub-banner_yellow_icon mb-0">
              <img
                src="./assets/images/sub_banner_yellow_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_va_icon mb-0">
              <img
                src="./assets/images/sub_banner_va_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_bottom_white_icon mb-0">
              <img
                src="./assets/images/sub_banner_bottom_white_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_right_top_icon mb-0">
              <img
                src="./assets/images/sub_banner_right_top_transparent_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_right_bottom_icon mb-0">
              <img
                src="./assets/images/sub_banner_right_bottom_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
          </div>
        </section>
      </div>
      {/* CASE STUDIES SECTION */}
      <section className="case_studies_section">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="case_studies-content">
                <h2>Our Esteemed Clients</h2>
                <br />
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-up">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
              <div className="case_studies_image text-center">
                <figure className="mb-0 d-flex justify-content-center">
                  <a
                    href="https://polisheyewear.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="./assets/images/polish.png"
                      alt=""
                      className="img-fluid product-image"
                      style={{
                        height: "auto", // Maintain aspect ratio
                        borderRadius: "50%", // Make it circular
                        marginTop: "20px", // Adjust margin as needed
                      }}
                    />
                  </a>
                </figure>

                <br />
                <div className="case_studies_box">
                  <h4>Polish Eyewear</h4>
                  <p>
                    Polish Eyewear: The Seamless Wholesale Marketplace for Top
                    Polish Eyewear. Elevate your eyewear inventory.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
              <div className="case_studies_image text-center">
                <figure className="mb-0">
                  <img
                    src="./assets/images/employee.png"
                    alt=""
                    className="img-fluid product-image mx-auto d-block"
                    style={{
                      maxWidth: "50%",
                      height: "auto",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </figure>
                <br />
                <div className="case_studies_box">
                  <h4>Employee Performance Evaluation</h4>
                  <p>
                    One Stop solution to evaluate your employee's performances.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
              <div className="case_studies_image text-center">
                <figure className="mb-0 d-inline-block">
                  <img
                    src="./assets/images/society_management_logo.png"
                    alt=""
                    className="img-fluid product-image"
                    style={{ maxWidth: "70%", height: "auto" }}
                  />
                </figure>
                <br />
                <div className="case_studies_box">
                  <h4>Society Management</h4>
                  <p>
                    Comprehensive Housing Society Management Solution.
                    Streamline maintenance, grievances, area bookings, and
                    events for a hasslefree community experience
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
              <div className="case_studies_image text-center">
                <figure className="mb-0">
                  <img
                    src="./assets/images/ed..png"
                    alt=""
                    className="img-fluid product-image"
                    style={{
                      maxWidth: "50%",
                      height: "auto",
                      margin: "20px auto", // Center the image horizontally
                    }}
                  />
                </figure>
                <br />
                <div className="case_studies_box">
                  <h4>Education Development</h4>
                  <p>
                    EDE is a blend of social interaction tailored for students
                    and teacher.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
              <div className="case_studies_image text-center">
                {/* <figure className="mb-0">
                  <img
                    src="./assets/images/slider-dec.png"
                    alt=""
                    className="img-fluid product-image"
                    style={{
                      maxWidth: "250px",
                      height: "auto",
                      // Center the image horizontally
                    }}
                  />
                </figure>
                <br /> */}
                {/* <div className="case_studies_box">
                  <h4>Pricing Calculator</h4>
                  <p>
                    Estimate your app development costs for iOS, Android with
                    our comprehensive app development cost calculator.
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* WHY CHOOSE US SECTION */}
      {/* <section class="choose_us-section">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-6 col-md-6 col-sm-12 col-xs-12"
              data-aos="fade-right"
            >
              <div class="choose_us_content">
                <h6>WHY CHOOSE US</h6>
                <h2>Maximizing Business Growth with Our Services.</h2>
                <p>
                  Unlock growth with our tailored services. We're committed to
                  maximizing your success through streamlined operations and
                  enhanced customer satisfaction.
                </p>
              </div>
              <div class="box_wrapper">
                <div class="box mb-0">
                  <h2 class="counter">100</h2>
                  <p class="sub_p mb-0">Happy Clients</p>
                </div>
                <div class="box mb-0">
                  <h2 class="counter">10</h2>
                  <p class="sub_p mb-0">Portfolio Done</p>
                </div>
                <div class="box mb-0 mr-0">
                  <h2 class="counter">20</h2>
                  <p class="sub_p mb-0">Awards Won</p>
                </div>
              </div>
              <figure class="choose_us_yellow_icon mb-0">
                <img
                  src="./assets/images/choose_us_yellow_icon.png"
                  alt=""
                  class="img-fluid"
                />
              </figure>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="choose_us_image">
                <figure class="mb-0">
                  <img
                    src="./assets/images/Designer11.jpeg"
                    alt=""
                    class="img-fluid"
                  />
                </figure>
              </div>
            </div>
          </div>
          <figure class="choose_us_left_icon mb-0">
            <img
              src="./assets/images/home_services_left_icon.png"
              alt=""
              class="img-fluid"
            />
          </figure>
          <figure class="choose_us_right_icon mb-0">
            <img
              src="./assets/images/home_services_right_icon.png"
              alt=""
              class="img-fluid"
            />
          </figure>
        </div>
      </section> */}

      {/* FOOTER SECTION */}
      <Footer />

      {/* Latest compiled JavaScript */}
    </>
  );
};
export default Project;
