import { Link, useLocation } from "react-router-dom";
import React from "react";
import "aos/dist/aos.css";

export const Header = () => {
  const location = useLocation();

  return (
    <div>
      <header>
        <div className="main_header">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light p-0 phone">
              <Link to="/">
                <figure className="mb-0 image">
                  <img
                    src="./assets/images/logo1.webp"
                    alt=""
                    className="img-fluid"
                    style={{ width: 150 }}
                  />
                </figure>
              </Link>
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                style={{ marginRight: "50px" }}
              >
                <span className="navbar-toggler-icon" />
                <span className="navbar-toggler-icon" />
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="collapse navbar-collapse menu"
                style={{ padding: "20px 0px" }}
                id="navbarSupportedContent"
              >
                <ul
                  className="navbar-nav options"
                  style={{ marginRight: "10px" }}
                >
                  <li
                    className={
                      location.pathname === "/"
                        ? "nav-item active option"
                        : "nav-item option"
                    }
                  >
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/service"
                        ? "nav-item active option"
                        : "nav-item option"
                    }
                  >
                    <Link to="/service">Service</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/project"
                        ? "nav-item active option"
                        : "nav-item option"
                    }
                  >
                    <Link to="/project">Portfolio</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/career"
                        ? "nav-item active option"
                        : "nav-item option"
                    }
                  >
                    <Link to="/career">Career</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/about"
                        ? "nav-item active option"
                        : "nav-item option"
                    }
                  >
                    <Link to="/about">About</Link>
                  </li>

                  {/* <li className="nav-item">
  <Link to="/contact">Contact</Link>
</li> */}
                </ul>
                {/* You can add other menu elements here */}
              </div>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};
