import React, { useRef } from "react";
import axios from "axios";
import "aos/dist/aos.css";

const Mail = () => {
  const form = useRef();

  const handleSubmit = async (e) => {
    // e.preventDefault();

    // try {
    //   const formData = new FormData(form.current);
    //   const jsonData = {};
    //   formData.forEach((value, key) => {
    //     jsonData[key] = value;
    //   });

    //   const response = await axios.post(
    //     "https://incronix.com/contact.php",
    //     jsonData
    //   );

    //   alert("Record submitted successfully!");
    //   form.current.reset();
    // } catch (error) {
    //   console.error("Error submitting record:", error);
    //   alert("Error submitting record");
    // }
    e.preventDefault();

    // Retrieve form data
    const formData = new FormData(form.current);

    const jsonData = {};
    formData.forEach((value, key) => {
      jsonData[key] = value;
    });

    // Send POST request to server with form data
    axios
      .post("https://incronix.com/contact.php", jsonData)
      .then((response) => {
        alert("Record submitted successfully!");

        form.current.reset(); // Reset the form after successful submission
      })
      .catch((error) => {
        console.error("Error submitting details:", error);
      });
  };
  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   // Retrieve form data
  //   const formData = new FormData(form.current);

  //   const jsonData = {};
  //   formData.forEach((value, key) => {
  //     jsonData[key] = value;
  //   });

  //   // Send POST request to server with form data
  //   axios
  //     .post("https://incronix.com/Backend/contact.php", jsonData)
  //     .then((response) => {
  //       // alert("Thank you for your response!");
  //       toast.success("Thank you for your response!", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //       });
  //       form.current.reset(); // Reset the form after successful submission
  //     })
  //     .catch((error) => {
  //       toast.error("An error occurred. Please try again later.", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //       });
  //       console.error("Error submitting response:", error);
  //     });
  // };

  return (
    <section className="send_message_section">
      <div className="container">
        <div className="row" data-aos="fade-up">
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
            <div className="send_message_box_content">
              <h6>READY TO</h6>
              <h2>Get Started With Us</h2>

              <h6>
                Pioneering technology meets
                <br />
                unmatched flexibility,
                <br /> delivering innovative answers for businesses across the
                globe.
                <br />
                <br />
                <br />
                <br />
              </h6>
            </div>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
            <div className="send_message_form_box_content">
              <h6>SEND US A MESSAGE</h6>
              <form ref={form} onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form_style"
                        placeholder="Name"
                        name="user_name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group mb-0">
                      <input
                        type="email"
                        className="form_style"
                        placeholder="Email"
                        name="user_mail"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group mb-0">
                      <input
                        type="tel"
                        className="form_style"
                        placeholder="Phone"
                        name="user_phone"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form_style mt-0"
                        placeholder="Subject"
                        name="subject"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mb-0">
                      <textarea
                        className="form_style"
                        placeholder="Message"
                        rows={3}
                        name="message"
                        defaultValue={""}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="form_button_wrapper text-center">
                  <button type="submit" className="appointment-btn">
                    Submit Now
                  </button>
                </div>
              </form>
            </div>
            <figure className="send_message_yellow_icon mb-0">
              <img
                src="./assets/images/send_message_yellow_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
          </div>
        </div>
        <figure className="send_message_left_icon mb-0">
          <img
            src="./assets/images/send_message_left_icon.png"
            alt=""
            className="img-fluid"
          />
        </figure>
        <figure className="send_message_right_icon mb-0">
          <img
            src="./assets/images/send_message_right_icon.png"
            alt=""
            className="img-fluid"
          />
        </figure>
        <figure className="send_message_bottom_icon mb-0">
          <img
            src="./assets/images/send_message_bottom_icon.png"
            alt=""
            className="img-fluid"
          />
        </figure>
      </div>
    </section>
  );
};

export default Mail;
