import React, { useState, useEffect } from "react";
import "./QuotationData.css";
import "./style.css";

function CareerPage() {
  const [careerData, setCareerData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch("https://incronix.com/career.php", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Sort the data by timestamp or index in descending order
        const sorted = data.sort((a, b) => {
          // Assuming data has a timestamp field
          return new Date(b.timestamp) - new Date(a.timestamp);
          // If no timestamp, use index as a makeshift timestamp:
          // return careerData.length - index;
        });
        setCareerData(sorted);
        setSortedData(sorted); // Initially set sorted data same as fetched data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const sortByName = () => {
    const sorted = [...careerData].sort((a, b) => a.name.localeCompare(b.name));
    setSortedData(sorted);
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filtered = careerData.filter((career) =>
      career.name.toLowerCase().includes(term.toLowerCase())
    );
    setSortedData(filtered);
  };

  return (
    <div className="page-container">
      <div className="quotation-container">
        <h2>Career</h2>
        <div>
          <input
            type="text"
            placeholder="Search by Name"
            value={searchTerm}
            onChange={handleSearch}
          />
          <button onClick={sortByName}>Sort by Name</button>
        </div>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Role</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {sortedData.map((career, index) => (
                <tr key={index}>
                  <td>{career.name}</td>
                  <td>{career.email}</td>
                  <td>{career.phone}</td>
                  <td>{career.role}</td>
                  <td>{career.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CareerPage;
