import React, { cloneElement, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const Contact = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 120,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
      />
      <meta charSet="utf-8" />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href="./assets/images/favicon/apple-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="./assets/images/favicon/apple-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="./assets/images/favicon/apple-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="./assets/images/favicon/apple-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="./assets/images/favicon/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="./assets/images/favicon/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="./assets/images/favicon/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="./assets/images/favicon/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="./assets/images/favicon/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="./assets/images/favicon/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="./assets/images/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="./assets/images/favicon/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="./assets/images/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="./assets/images/favicon/manifest.json" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />

      <link href="assets/bootstrap/bootstrap.min.css" rel="stylesheet" />
      <link rel="stylesheet" href="./assets/js/bootstrap.min.js" />

      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
      />

      <link href="assets/css/style.css" rel="stylesheet" type="text/css" />
      <link
        href="assets/css/mediaqueries.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="assets/css/owl.carousel.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="assets/css/owl.theme.default.min.css"
        rel="stylesheet"
        type="text/css"
      />

      <div className="sub-banner-section">
        <header>
          <div className="main_header">
            <div className="container-fluid">
              <nav className="navbar navbar-expand-lg navbar-light p-0 phone">
                <Link to="/">
                  <figure className="mb-0 image">
                    <img
                      src="./assets/images/logo1.webp"
                      alt=""
                      className="img-fluid"
                      style={{ width: 150 }}
                    />
                  </figure>
                </Link>
                <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  style={{ marginRight: "70px" }}
                >
                  <span className="navbar-toggler-icon" />
                  <span className="navbar-toggler-icon" />
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="collapse navbar-collapse menu"
                  style={{ padding: "10px" }}
                  id="navbarSupportedContent"
                >
                  <ul
                    className="navbar-nav options"
                    style={{ marginRight: "10px" }}
                  >
                    <li className="nav-item active option">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="nav-item option">
                      <Link to="/service">Service</Link>
                    </li>
                    <li className="nav-item option">
                      <Link to="/project">Project</Link>
                    </li>
                    <li className="nav-item option">
                      <Link to="/about">About</Link>
                    </li>
                    <li className="nav-item option">
                      <Link to="/career">Career</Link>
                    </li>
                    {/* <li className="nav-item">
        <Link to="/contact">Contact</Link>
      </li> */}
                  </ul>
                  {/* You can add other menu elements here */}
                </div>
              </nav>
            </div>
          </div>
        </header>
        {/* SUB BANNER SECTION */}
        <section className="banner-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-md-left text-center">
                <div className="banner-section-content">
                  <h1 data-aos="fade-up">Contact Us</h1>
                  <p data-aos="fade-right">
                    At INCRONIX, we prioritize innovation and excellence in all
                    our projects, consistently exceeding expectations from start
                    to finish. Contact us today to discover how we can elevate
                    your business
                  </p>
                  <div className="btn_wrapper">
                    <span>Home </span>
                    <i
                      className="fa-solid fa-angles-right"
                      aria-hidden="true"
                    />
                    <span className="sub_span"> Contact</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="banner-section-image">
                  <figure className="mb-0">
                    <img
                      src="./assets/images/contact.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </figure>
                </div>
                <figure className="sub-banner_yellow_green_icon mb-0">
                  <img
                    src="./assets/images/sub_banner_yellow_green_icon.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
            <figure className="sub-banner_yellow_icon mb-0">
              <img
                src="./assets/images/sub_banner_yellow_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_va_icon mb-0">
              <img
                src="./assets/images/sub_banner_va_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_bottom_white_icon mb-0">
              <img
                src="./assets/images/sub_banner_bottom_white_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_right_top_icon mb-0">
              <img
                src="./assets/images/sub_banner_right_top_transparent_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_right_bottom_icon mb-0">
              <img
                src="./assets/images/sub_banner_right_bottom_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
          </div>
        </section>
      </div>
      {/* GET IN TOUCH SECTION */}
      <section className="in_touch_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="in_touch-content">
                <h6>GET IN TOUCH</h6>
                <h2>Our Contact Information</h2>
                <p>
                  We’d love to know more about your project. Contact us at
                  hr@incronix.com or simply fill up the form.
                </p>
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-up">
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div className="in_touch_box">
                <div className="in_touch_box_image">
                  <figure className="mb-0">
                    <img
                      src="./assets/images/contact_location_image.png"
                      alt=""
                      className="img-fluid"
                    />
                  </figure>
                </div>
                <div className="in_touch_box_content">
                  <h4>Location</h4>
                  <p className="mb-0">
                    Office no.16, 2nd Floor, Apurva Complex, Ganeshkhind Rd
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div className="in_touch_box in_touch_box2">
                <div className="in_touch_box_image">
                  <figure className="mb-0">
                    <img
                      src="./assets/images/contact_phone_image.png"
                      alt=""
                      className="img-fluid"
                    />
                  </figure>
                </div>
                <div className="in_touch_box_content">
                  <h4>Phone</h4>
                  <p className="mb-0">
                    <a
                      href="tel:(+61383766284)"
                      className="text-decoration-none"
                    >
                      999999999
                    </a>
                  </p>
                  <p className="mb-0">
                    <a
                      href="tel:(+80023456789)"
                      className="text-decoration-none"
                    >
                      99999999999
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <div className="in_touch_box in_touch_box2">
                <div className="in_touch_box_image">
                  <figure className="mb-0">
                    <img
                      src="./assets/images/contact_mail_image.png"
                      alt=""
                      className="img-fluid"
                    />
                  </figure>
                </div>
                <div className="in_touch_box_content">
                  <h4>Email</h4>
                  <p className="mb-0">
                    <a
                      href="mailto:info@yogastic.com"
                      className="text-decoration-none"
                    >
                      hr.incronix@gmail.com
                    </a>
                  </p>
                  <p className="mb-0">
                    <a
                      href="mailto:yogastic@gmail.com"
                      className="text-decoration-none"
                    ></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <figure className="in_touch_shape mb-0">
            <img
              src="./assets/images/in_touch_shape.png"
              alt=""
              className="img-fluid"
              data-aos="fade-up"
            />
          </figure>
        </div>
      </section>

      {/* SEND MESSAGE SECTION */}
      <section className="send_message_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
              <div className="send_message_box_content">
                <h6>READY TO</h6>
                <h2>Get Started With Us</h2>
                <div className="btn_wrapper">
                  <a className="text-decoration-none get_started_btn" href="/">
                    Get Started
                  </a>
                </div>
                <i className="fa-solid fa-headphones" />
                <span className="call_us d-block">Call us Today:</span>
                <span className="phone_num">000 8596 256</span>
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
              <div className="send_message_form_box_content">
                <h6>SEND US A MESSAGE</h6>
                <form
                  id="contactpage"
                  method="POST"
                  action="./contact-form.php"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          className="form_style"
                          placeholder="Name"
                          name="name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-0">
                        <input
                          type="email"
                          className="form_style"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-0">
                        <input
                          type="tel"
                          className="form_style"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          className="form_style"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className=" form-group mb-0">
                        <textarea
                          className="form_style"
                          placeholder="Message"
                          rows={3}
                          name="comments"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form_button_wrapper text-center">
                    <button type="submit" className="appointment-btn">
                      Submit Now
                    </button>
                  </div>
                </form>
              </div>
              <figure className="send_message_yellow_icon mb-0">
                <img
                  src="./assets/images/send_message_yellow_icon.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
            </div>
          </div>
          <figure className="send_message_left_icon mb-0">
            <img
              src="./assets/images/send_message_left_icon.png"
              alt=""
              className="img-fluid"
            />
          </figure>
          <figure className="send_message_right_icon mb-0">
            <img
              src="./assets/images/send_message_right_icon.png"
              alt=""
              className="img-fluid"
            />
          </figure>
          <figure className="send_message_bottom_icon mb-0">
            <img
              src="./assets/images/send_message_bottom_icon.png"
              alt=""
              className="img-fluid"
            />
          </figure>
        </div>
      </section>

      {/* FOOTER SECTION */}
      <section className="footer-section">
        <div className="container">
          <div className="middle-portion">
            <div className="row">
              <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 d-lg-block d-none" />
              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <div className="about_col">
                  <h4>About Us</h4>
                  <ul className="list-unstyled">
                    <li>
                      <p className="pp">
                        Incronix is a dynamic force in the technology arena,
                        relentlessly shaping the landscape of innovation.
                      </p>
                    </li>
                    <li className="icons">
                      <a href="#">
                        <i
                          className="fa-brands fa-facebook-f"
                          aria-hidden="true"
                        />
                      </a>
                    </li>
                    <li className="icons">
                      <a href="#">
                        <i
                          className="fa-brands fa-twitter"
                          aria-hidden="true"
                        />
                      </a>
                    </li>
                    <li className="icons">
                      <a href="#">
                        <i
                          className="fa-brands fa-linkedin-in mr-0"
                          aria-hidden="true"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-3 col-xs-12 d-md-block d-none">
                <div className="links_col">
                  <h4>Useful Links</h4>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/service">Service</Link>
                    </li>
                    <li>
                      <Link to="/career">Career</Link>
                    </li>
                    <li>
                      <Link to="/project">Project</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div className="contact_col">
                  <h4>Contact Info</h4>
                  <ul className="list-unstyled">
                    <li>
                      <i className="fa-sharp fa-solid fa-envelope" />
                      <a
                        href="mailto:hr@incronix.com"
                        className="text-decoration-none"
                      >
                        hr@incronix.com
                      </a>
                    </li>
                    <li className="mb-0">
                      <i className="fa-solid fa-location-dot location" />
                      <a className="text-decoration-none">
                        Office no.16, 2nd Floor, Apurva Complex, Ganeshkhind Rd,
                        above Katara Dental, Jagdishnagar, Sadhu Vasvani Nagar,
                        Aundh, Pune, Maharashtra 411007
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-portion">
          <div className="copyright col-xl-12">
            <p>© 2024 INCRONIX TECHNOLOGY, All rights reserved.</p>
          </div>
        </div>
      </section>
      {/* Latest compiled JavaScript */}
    </>
  );
};

export default Contact;
