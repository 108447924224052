import React from "react";
import { useParams } from "react-router-dom";
import styles from "./Certificate.module.css";
import certificates from "./data";
import logo from "../Certificates/biglogo3.png";
import yellowIcon from "../Certificates/sub_banner_yellow_icon.png";
import orangeIcon from "../Certificates/sub_banner_right_top_transparent_icon.png";

const Certificate = () => {
  const { id } = useParams();
  const certificateData = certificates[id];

  if (!certificateData) {
    return (
      <div className={styles.errorContainer}>
        <p>Certificate not found.</p>
      </div>
    );
  }

  const { src } = certificateData;
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <div className={styles.certificateContainer}>
      <div className={styles.certificateImage}>
        <img
          src={src || logo}
          alt="Certificate"
          onContextMenu={handleContextMenu}
          draggable="false"
        />
      </div>
      <div className={styles.logoContainer}>
        <img src={logo} alt="Logo" className={styles.circularLogo} />
      </div>
      {/* Uncomment this block if you want to display the name */}
      {/* {name && (
        <div className={styles.nameContainer}>
          <h2>{name}</h2>
        </div>
      )} */}
      <div className={styles.yellowIconContainer}>
        <img src={yellowIcon} alt="Yellow Icon" className={styles.yellowIcon} />
      </div>
      <div className={styles.orangeIconContainer}>
        <img src={orangeIcon} alt="Orange Icon" className={styles.orangeIcon} />
      </div>
    </div>
  );
};

export default Certificate;
