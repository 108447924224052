import React, { cloneElement, useEffect } from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Product.css";
import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 120,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
      />
      <meta charSet="utf-8" />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href="./assets/images/favicon/apple-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="./assets/images/favicon/apple-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="./assets/images/favicon/apple-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="./assets/images/favicon/apple-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="./assets/images/favicon/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="./assets/images/favicon/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="./assets/images/favicon/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="./assets/images/favicon/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="./assets/images/favicon/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="./assets/images/favicon/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="./assets/images/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="./assets/images/favicon/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="./assets/images/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="./assets/images/favicon/manifest.json" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      {/* Latest compiled and minified CSS */}
      <link href="assets/bootstrap/bootstrap.min.css" rel="stylesheet" />
      <link rel="stylesheet" href="./assets/js/bootstrap.min.js" />
      {/* Font Awesome link */}
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
      />
      {/* StyleSheet link CSS */}
      <link href="assets/css/style.css" rel="stylesheet" type="text/css" />
      <link
        href="assets/css/mediaqueries.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="assets/css/owl.carousel.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="assets/css/owl.theme.default.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <div className="sub-banner-section">
        <div class="banner-section-outer">
          <Header/>
          <section className="banner-section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-md-left text-center">
                  <div className="banner-section-content">
                    <h1
                      className="space"
                      style={{ fontSize: "20" }}
                      data-aos="fade-up"
                    >
                      Shaping the Landscape of Innovation.
                    </h1>
                    <p data-aos="fade-up">
                      Where innovative technology intersects with steadfast
                      flexibility, facilitating revolutionary solutions for
                      global enterprises.
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                  data-aos="fade-left"
                >
                  <div className="banner-section-image">
                    <figure className="mb-0">
                      <img
                        src="./assets/images/biglogo6.webp"
                        alt=""
                        className="img-fluid"
                        style={{
                          marginTop: "-20px",
                          marginLeft: "auto",
                          maxWidth: "100%",
                        }}
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <figure
                className="sub-banner_yellow_icon mb-0"
                data-aos="fade-right"
              >
                <img
                  src="./assets/images/sub_banner_yellow_icon.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
              <figure className="sub-banner_va_icon mb-0" data-aos="fade-left">
                <img
                  src="./assets/images/sub_banner_va_icon.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
              <figure
                className="sub-banner_bottom_white_icon mb-0"
                data-aos="fade-up"
              >
                <img
                  src="./assets/images/sub_banner_bottom_white_icon.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>

              <figure
                className="sub-banner_right_top_icon mb-0"
                data-aos="fade-up"
              >
                <img
                  src="./assets/images/sub_banner_right_top_transparent_icon.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
              <figure
                className="sub-banner_right_bottom_icon mb-0"
                data-aos="fade-up"
              >
                <img
                  src="./assets/images/sub_banner_right_bottom_icon.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
            </div>
          </section>
        </div>
      </div>
      <section className="aboutus_section">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div>
                <figure className="mb-0 d-flex">
                  <img
                    src="./assets/images/Designer7.jpeg"
                    alt=""
                    style={{
                      maxWidth: "100%", // Set maximum width to 100%
                      height: "auto", // Maintain aspect ratio
                      borderRadius: 40,
                      marginTop: 20,
                      marginLeft: "5px",
                    }}
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="aboutus_content">
                <h2>
                  Our Business is to Making Your life Easier & Comfortable
                </h2>
                <p>INNOVATION SPARKS ,INCRONIX :INNOVATE</p>

                <p className="pp">
                  Welcome to Incronix, where innovation simplifies and enhances
                  your life. We're dedicated to creating cutting-edge solutions
                  that make everyday tasks easier and more comfortable. With a
                  focus on responsible innovation, our user-friendly and
                  sustainable products aim to make a real difference. Join us on
                  our journey to transform your world. Discover the power of
                  innovation with Incronix today!
                </p>
              </div>
            </div>
          </div>
          <figure className="aboutus_shape mb-0">
            <img
              src="./assets/images/aboutus_shape.png"
              alt=""
              className="img-fluid"
            />
          </figure>
        </div>
      </section>

      <section class="aboutus_section">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="aboutus_content">
                <h2>CHOOSE INCRONIX</h2>
                <p className="pp">
                  INCRONIX is a dynamic force in the technology arena,
                  relentlessly shaping the landscape of innovation. Our
                  solutions equip businesses and individuals with the tools they
                  need to not only survive, but flourish, in any situation.
                  We're relentless in our pursuit of groundbreaking ideas,
                  constantly pushing the limits of what's possible. By providing
                  a strong and adaptable base, we empower our clients to
                  navigate change with certainty and capitalize on emerging
                  opportunities.
                </p>
                <div className="btn_wrapper" style={{ marginTop: 10 }}>
                  <Link
                    className="text-decoration-none readmore_btn"
                    to="/about"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div>
                <figure className="mb-0 d-flex justify-content-center">
                  <img
                    src="./assets/images/service5.jpeg"
                    alt=""
                    style={{
                      maxWidth: "100%", // Set maximum width to 100%
                      height: "auto", // Maintain aspect ratio
                      borderRadius: 40,
                      marginTop: 20,
                      marginLeft: "10px",
                    }}
                  />
                </figure>
              </div>
            </div>
          </div>
          <figure className="aboutus_shape mb-0">
            <img
              src="./assets/images/aboutus_shape.png"
              alt=""
              className="img-fluid"
            />
          </figure>
        </div>
      </section>

      <section className="case_studies_section">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div
                className="case_studies-content"
                style={{ textAlign: "center" }}
              >
                <h2>Our Esteemed Clients</h2>
                <br />
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-up">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
              <div className="case_studies_image text-center">
                <figure className="mb-0 d-flex justify-content-center">
                  <img
                    src="./assets/images/polish.png"
                    alt=""
                    className="img-fluid product-image"
                    style={{
                      height: "auto", // Maintain aspect ratio
                      borderRadius: "45%", // Make it circular
                      marginTop: "20px", // Adjust margin as needed
                    }}
                  />
                </figure>

                <br />
                <div className="case_studies_box">
                  <h4>Polish Eyewear</h4>
                  <p>
                    Polish Eyewear: The Seamless Wholesale Marketplace for Top
                    Polish Eyewear. Elevate your eyewear inventory.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
              <div className="case_studies_image text-center">
                <figure className="mb-0">
                  <img
                    src="./assets/images/employee.png"
                    alt=""
                    className="img-fluid product-image mx-auto d-block"
                    style={{
                      maxWidth: "45%",
                      height: "auto",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </figure>
                <br />
                <div className="case_studies_box">
                  <h4>Employee Performance Evaluation</h4>
                  <p>
                    One Stop solution to evaluate your employee's performances.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4">
              <div className="case_studies_image text-center">
                <figure className="mb-0">
                  <img
                    src="./assets/images/ed..png"
                    alt=""
                    className="img-fluid product-image"
                    style={{
                      maxWidth: "45%",
                      height: "auto",
                      margin: "20px auto", // Center the image horizontally
                    }}
                  />
                </figure>
                <br />
                <div className="case_studies_box">
                  <h4>Education Development</h4>
                  <p>
                    EDE is a blend of social interaction tailored for students
                    and teacher.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FOOTER SECTION */}
      <Footer/>

      {/* Latest compiled JavaScript */}
    </>
  );
};
export default Home;
