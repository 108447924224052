import React, { cloneElement, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import Career_mail from "../Components/Career_mail";
import AOS from "aos";
import "aos/dist/aos.css";
import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";

const Career = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 120,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
      />
      <meta charSet="utf-8" />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href="./assets/images/favicon/apple-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="./assets/images/favicon/apple-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="./assets/images/favicon/apple-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="./assets/images/favicon/apple-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="./assets/images/favicon/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="./assets/images/favicon/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="./assets/images/favicon/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="./assets/images/favicon/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="./assets/images/favicon/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="./assets/images/favicon/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="./assets/images/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="./assets/images/favicon/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="./assets/images/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="./assets/images/favicon/manifest.json" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      {/* Latest compiled and minified CSS */}
      <link href="assets/bootstrap/bootstrap.min.css" rel="stylesheet" />
      <link rel="stylesheet" href="./assets/js/bootstrap.min.js" />
      {/* Font Awesome link */}
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
      />
      {/* StyleSheet link CSS */}
      <link href="assets/css/style.css" rel="stylesheet" type="text/css" />
      <link
        href="assets/css/mediaqueries.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="assets/css/owl.carousel.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="assets/css/owl.theme.default.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <div className="sub-banner-section">
        <Header />
        {/* SUB BANNER SECTION */}
        <section className="banner-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-md-left text-center">
                <div className="banner-section-content">
                  <h1 data-aos="fade-up">Career</h1>
                  <p data-aos="fade-right">
                    Join our team at INCRONIX and be a part of driving
                    innovation and excellence in every project. Explore exciting
                    career opportunities with us today
                  </p>
                  <div className="btn_wrapper">
                    <span>Home </span>
                    <i
                      className="fa-solid fa-angles-right"
                      aria-hidden="true"
                    />
                    <span className="sub_span"> Career</span>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                data-aos="fade-left"
              >
                <div className="banner-section-image">
                  <figure className="mb-0">
                    <img
                      src="./assets/images/biglogo6.webp"
                      alt=""
                      className="img-fluid"
                      style={{
                        marginTop: "-20px",
                        marginLeft: "auto",
                        maxWidth: "100%",
                      }}
                    />
                  </figure>
                </div>
              </div>
            </div>
            <figure className="sub-banner_yellow_icon mb-0">
              <img
                src="./assets/images/sub_banner_yellow_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_va_icon mb-0">
              <img
                src="./assets/images/sub_banner_va_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_bottom_white_icon mb-0">
              <img
                src="./assets/images/sub_banner_bottom_white_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_right_top_icon mb-0">
              <img
                src="./assets/images/sub_banner_right_top_transparent_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_right_bottom_icon mb-0">
              <img
                src="./assets/images/sub_banner_right_bottom_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
          </div>
        </section>
      </div>

      {/* SEND MESSAGE SECTION */}

      <Career_mail />

      {/* FOOTER SECTION */}
      <Footer />

      {/* Latest compiled JavaScript */}
    </>
  );
};
export default Career;
