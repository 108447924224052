import { Link } from "react-router-dom";
import React from "react";
import "aos/dist/aos.css";

export const Footer = () => {
  return (
    <div>
      <section className="footer-section">
        <div className="container">
          <div className="middle-portion">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 d-lg-block d-none" />
              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <div className="about_col">
                  <h4>Social Media</h4>
                  <ul className="list-unstyled">
                    <li>
                      <p className="pp">
                        Connect with us on social media for updates
                      </p>
                    </li>
                    <ul
                      className="icons"
                      style={{ listStyle: "none", padding: 0 }}
                    >
                      <li
                        className="icons"
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <a
                          href="https://www.instagram.com/incronix_technology/?hl=en"
                          target="_blank" // Add target="_blank" to open link in new tab
                          rel="noopener noreferrer"
                        >
                          <i
                            className="fa-brands fa-instagram"
                            aria-hidden="true"
                          />
                        </a>
                      </li>
                      <li
                        className="icons"
                        style={{ display: "inline-block", marginRight: "10px" }}
                      ></li>
                      <li
                        className="icons"
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <a
                          href="https://www.linkedin.com/company/incronix-technology/"
                          target="_blank" // Add target="_blank" to open link in new tab
                          rel="noopener noreferrer" // Add rel="noopener noreferrer" for security
                        >
                          <i
                            className="fa-brands fa-linkedin-in"
                            aria-hidden="true"
                          />
                        </a>
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-3 col-xs-12 d-md-block d-none">
                <div className="links_col">
                  <h4>Useful Links</h4>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/service">Service</Link>
                    </li>
                    <li>
                      <Link to="/project">Portfolio</Link>
                    </li>
                    <li>
                      <Link to="/career">Career</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>

                    {/* <li>
                  <Link to="/contact">Contact</Link>
                </li> */}
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div className="contact_col">
                  <h4>Contact Info</h4>
                  <ul className="list-unstyled">
                    <li>
                      <i className="fa-sharp fa-solid fa-envelope" />
                      <a
                        href="mailto:hr@incronix.com"
                        className="text-decoration-none"
                      >
                        hr@incronix.com
                      </a>
                    </li>
                    <li className="mb-0">
                      <i className="fa-solid fa-location-dot location" />
                      <a className="text-decoration-none"> Aundh, Pune</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-portion">
          <div className="copyright col-xl-12">
            <p style={{ fontSize: "15px" }}>
              © 2024 INCRONIX TECHNOLOGY PVT LTD
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
