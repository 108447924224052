import React, { useState, useRef, useEffect } from "react";
import "./contactform.css";
import "./style.css";
import axios from "axios";

function Calculation() {
  const [currentComponent, setCurrentComponent] = useState("platformSelection");
  const [step, setStep] = useState(1);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedWebsiteTypes, setSelectedWebsiteTypes] = useState([]);
  const [selectedNumberOfPages, setSelectedNumberOfPages] = useState("");
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectedLoginOptions, setSelectedLoginOptions] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedAdminPanel, setSelectedAdminPanel] = useState("");
  const [selectedDesignLogo, setSelectedDesignLogo] = useState("");
  const [contactFormData, setContactFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleNext = (data) => {
    switch (step) {
      case 1:
        setSelectedPlatforms(data);
        setStep(step + 1);
        break;
      case 2:
        setSelectedWebsiteTypes(data);
        setStep(step + 1);
        break;
      case 3:
        setSelectedNumberOfPages(data);
        setStep(step + 1);
        break;
      case 4:
        setSelectedPages(data);
        setStep(step + 1);
        break;
      case 5:
        setSelectedLoginOptions(data);
        setStep(step + 1);
        break;
      case 6:
        setSelectedFeatures(data);
        setStep(step + 1);
        break;
      case 7:
        setSelectedAdminPanel(data);
        setStep(step + 1);
        break;
      case 8:
        setSelectedDesignLogo(data);
        setStep(step + 1);
        break;
      case 9:
        setContactFormData(data);
        setStep(step + 1);
        break;
      // Add cases for other steps here
      default:
        break;
    }
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const PlatformSelection = ({ handleNext, handlePrevious }) => {
    const [platformOptions, setPlatformOptions] = useState([
      { id: 1, name: "Web-based Platform", checked: false, value: 1000 },
      { id: 2, name: "IOS", checked: false, value: 1000 },
      { id: 3, name: "Android", checked: false, value: 1000 },
      { id: 4, name: "Desktop", checked: false, value: 1000 },
    ]);

    const [showPopup, setShowPopup] = useState(false); // State for popup visibility

    const handleCheckboxChange = (id) => {
      setPlatformOptions((prevOptions) =>
        prevOptions.map((option) =>
          option.id === id ? { ...option, checked: !option.checked } : option
        )
      );
    };

    useEffect(() => {
      localStorage.setItem("platformOptions", JSON.stringify(platformOptions));
    }, [platformOptions]);

    const handleNextClick = () => {
      const selectedOptions = platformOptions.filter(
        (option) => option.checked
      );

      if (selectedOptions.length > 0) {
        handleNext(selectedOptions);
        setShowPopup(false); // Hide popup if selections are made
      } else {
        setShowPopup(true); // Show popup if no selections are made
      }
    };

    return (
      <div className="calculator">
        <div className="content">
          <h2>Select the Platform:</h2>
          <ul className="ks-cboxtags">
            {platformOptions.map((option) => (
              <li key={option.id}>
                <input
                  type="checkbox"
                  id={`checkbox${option.id}`}
                  checked={option.checked}
                  onChange={() => handleCheckboxChange(option.id)}
                />
                <label htmlFor={`checkbox${option.id}`}>{option.name}</label>
              </li>
            ))}
          </ul>

          {showPopup && ( // Conditionally render popup based on state
            <div className="popup">
              <div className="popup-header">
                Please Select a Platform
                <span className="close" onClick={() => setShowPopup(false)}>
                  &times;
                </span>
              </div>
              <div className="popup-body">
                You haven't selected any platform options. Please choose at
                least one platform to proceed.
              </div>
              <div className="popup-footer">
                {/* No "Got it!" button needed here */}
              </div>
            </div>
          )}

          <div className="button-group">
            <button onClick={handleNextClick}>Next</button>
          </div>
        </div>
      </div>
    );
  };
  const WebsiteTypeSelection = ({ handleNext, handlePrevious }) => {
    const [websiteTypes, setWebsiteTypes] = useState([
      {
        id: 1,
        name: "Social Networking / Social Sharing",
        checked: false,
        value: 1000,
      },
      {
        id: 2,
        name: "Lifestyle / Health and Fitness",
        checked: false,
        value: 1000,
      },
      {
        id: 3,
        name: "E-Commerce / Shopping Apps",
        checked: false,
        value: 1000,
      },
      { id: 4, name: "Education and E-Learning", checked: false, value: 1000 },
      {
        id: 5,
        name: "Service Apps (e.g., UrbanClap)",
        checked: false,
        value: 1000,
      },
      {
        id: 6,
        name: "Booking Apps (Hotel, Flight, etc.)",
        checked: false,
        value: 1000,
      },
      { id: 7, name: "NGO & Fundraising", checked: false, value: 1000 },
      {
        id: 8,
        name: "Transport & Logistics Management",
        checked: false,
        value: 1000,
      },
      { id: 9, name: "Real-Estate", checked: false, value: 1000 },
      { id: 10, name: "Custom", checked: false, value: 1000 },
    ]);

    const [showPopup, setShowPopup] = useState(false); // State for popup visibility
    const [customValue, setCustomValue] = useState(() => {
      const storedCustomValue = localStorage.getItem("websiteCustomValue");
      return storedCustomValue || "";
    });
    const [showCustomPopup, setShowCustomPopup] = useState(false);

    const handleCheckboxChange = (id) => {
      setWebsiteTypes((prevTypes) =>
        prevTypes.map((type) =>
          type.id === id
            ? { ...type, checked: !type.checked }
            : { ...type, checked: false }
        )
      );
    };

    useEffect(() => {
      localStorage.setItem("websiteTypes", JSON.stringify(websiteTypes));
    }, [websiteTypes]);

    useEffect(() => {
      localStorage.setItem("websiteCustomValue", customValue);
    }, [customValue]);

    const handleCustomInputChange = (e) => {
      setCustomValue(e.target.value);
      setWebsiteTypes((prevTypes) =>
        prevTypes.map((type) =>
          type.name === "Custom"
            ? { ...type, checked: true }
            : { ...type, checked: false }
        )
      );
    };

    const handleNextClick = () => {
      const selectedType = websiteTypes.find((type) => type.checked);

      if (
        selectedType &&
        selectedType.name === "Custom" &&
        customValue.trim() === ""
      ) {
        setShowCustomPopup(true);
      } else if (
        !selectedType ||
        (selectedType.name === "Custom" && customValue.trim() === "")
      ) {
        setShowPopup(true);
      } else {
        handleNext([selectedType]);
      }
    };

    return (
      <div className="calculator">
        <div className="content">
          <h2>Select the Website/App Type:</h2>
          <ul className="ks-cboxtags">
            {websiteTypes.map((type) => (
              <li key={type.id}>
                <input
                  type="checkbox"
                  id={`checkbox${type.id}`}
                  checked={type.checked}
                  onChange={() => handleCheckboxChange(type.id)}
                />
                <label htmlFor={`checkbox${type.id}`}>{type.name}</label>
              </li>
            ))}
          </ul>

          {showPopup && (
            <div className="popup">
              <div className="popup-header">
                Please Select a Website/App Type
                <span className="close" onClick={() => setShowPopup(false)}>
                  &times;
                </span>
              </div>
              <div className="popup-body">
                You haven't selected any website/app types or filled the custom
                textbox. Please choose at least one type to proceed.
              </div>
            </div>
          )}

          {showCustomPopup && (
            <div className="popup">
              <div className="popup-header">
                Please Fill the Custom Checkbox
                <span
                  className="close"
                  onClick={() => setShowCustomPopup(false)}
                >
                  &times;
                </span>
              </div>
              <div className="popup-body">
                You haven't filled the custom textbox. Please fill it to
                proceed.
              </div>
            </div>
          )}

          <input
            type="text"
            value={customValue}
            onChange={handleCustomInputChange}
            placeholder="Custom"
            style={{
              display: websiteTypes.find(
                (type) => type.checked && type.name === "Custom"
              )
                ? "block"
                : "none",
            }}
          />

          <div className="button-group">
            <button onClick={handlePrevious}>Previous</button>
            <button onClick={handleNextClick}>Next</button>
          </div>
        </div>
      </div>
    );
  };

  const NumberOfPagesSelection = ({ handleNext, handlePrevious }) => {
    const [numberOfPages, setNumberOfPages] = useState([
      { value: "1-5", label: "1-5 Pages/Screens", checked: false, cost: 1000 },
      {
        value: "6-10",
        label: "6-10 Pages/Screens",
        checked: false,
        cost: 1000,
      },
      {
        value: "11-20",
        label: "11-20 Pages/Screens",
        checked: false,
        cost: 1000,
      },
      {
        value: "21-50",
        label: "21-50 Pages/Screens",
        checked: false,
        cost: 1000,
      },
      { value: "50+", label: "50+ Pages/Screens", checked: false, cost: 1000 },
    ]);

    const [showPopup, setShowPopup] = useState(false); // State for popup visibility

    const handleCheckboxChange = (value) => {
      setNumberOfPages((prevPages) =>
        prevPages.map((page) => ({
          ...page,
          checked: page.value === value,
        }))
      );
    };

    useEffect(() => {
      localStorage.setItem("numberOfPages", JSON.stringify(numberOfPages));
    }, [numberOfPages]);

    const handleNextClick = () => {
      const selectedPages = numberOfPages.find((page) => page.checked);

      if (selectedPages) {
        handleNext(selectedPages);
      } else {
        setShowPopup(true); // Show popup if no selections are made
      }
    };

    return (
      <div className="box-container">
        <div className="hello">
          <div className="content">
            <h2>
              Select the number of
              <br />
              pages/screens:
            </h2>
            <div className="spacing">
              <ul className="ks-cboxtags">
                {numberOfPages.map((page) => (
                  <li key={page.value}>
                    <input
                      type="checkbox"
                      id={`checkbox${page.value}`} // Fixed template string usage
                      checked={page.checked}
                      onChange={() => handleCheckboxChange(page.value)}
                    />
                    <label htmlFor={`checkbox${page.value}`}>
                      {" "}
                      {/* Fixed template string usage */}
                      {page.label}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            <div className="button-group">
              <button onClick={handlePrevious}>Previous</button>
              <button onClick={handleNextClick}>Next</button>
            </div>
          </div>
        </div>

        {showPopup && ( // Conditionally render popup based on state
          <div className="popup">
            <div className="popup-header">
              Please Select the Number of Pages
              <span className="close" onClick={() => setShowPopup(false)}>
                &times;
              </span>
            </div>
            <div className="popup-body">
              You haven't selected the number of pages/screens. Please choose
              one to proceed.
            </div>
          </div>
        )}
      </div>
    );
  };

  const PagesScreenNameSelection = ({ handleNext, handlePrevious }) => {
    const [pageNames, setPageNames] = useState([
      { id: 1, name: "Home", checked: false, value: 1000 },
      { id: 2, name: "Mission Page", checked: false, value: 1000 },
      { id: 3, name: "About Us", checked: false, value: 1000 },
      { id: 4, name: "Contact Us", checked: false, value: 1000 },
      { id: 5, name: "Product", checked: false, value: 1000 },
      { id: 6, name: "Portfolio", checked: false, value: 1000 },
      { id: 7, name: "Blog", checked: false, value: 1000 },
      { id: 8, name: "Awards", checked: false, value: 1000 },
      { id: 9, name: "Terms & Conditions", checked: false, value: 1000 },
      { id: 10, name: "Custom", checked: false, value: 1000 },
    ]);

    const [showCustomPopup, setShowCustomPopup] = useState(false);
    const [customValue, setCustomValue] = useState(() => {
      const storedCustomValue = localStorage.getItem("customValue");
      return storedCustomValue || "";
    });
    const [showNoSelectionPopup, setShowNoSelectionPopup] = useState(false);

    const handleCheckboxChange = (id) => {
      setPageNames((prevNames) =>
        prevNames.map((name) =>
          name.id === id ? { ...name, checked: !name.checked } : name
        )
      );
    };

    useEffect(() => {
      localStorage.setItem("pageNames", JSON.stringify(pageNames));
    }, [pageNames]);

    useEffect(() => {
      localStorage.setItem("customValue", customValue);
    }, [customValue]);

    const handleCustomInputChange = (e) => {
      setCustomValue(e.target.value);
    };

    const handleNextClick = () => {
      const customOption = pageNames.find((type) => type.name === "Custom");
      const hasSelected = pageNames.some((name) => name.checked);

      if (customOption.checked && customValue.trim() === "") {
        setShowCustomPopup(true);
      } else if (!hasSelected) {
        setShowNoSelectionPopup(true);
      } else {
        const selectedNames = pageNames.filter((name) => name.checked);
        handleNext(selectedNames);
      }
    };

    return (
      <div className="box-container">
        <div className="hello">
          <div className="content">
            <h2>
              Select Pages/Screen <br />
              Names:
            </h2>
            <div className="spacing">
              <ul className="ks-cboxtags">
                {pageNames.map((name) => (
                  <li key={name.id}>
                    <input
                      type="checkbox"
                      id={`checkbox${name.id}`}
                      checked={name.checked}
                      onChange={() => handleCheckboxChange(name.id)}
                    />
                    <label htmlFor={`checkbox${name.id}`}>{name.name}</label>
                  </li>
                ))}
              </ul>
              <input
                className="form-control"
                type="text"
                value={customValue}
                onChange={handleCustomInputChange}
                placeholder="Custom"
                aria-label="Custom input"
                style={{
                  display: pageNames.find(
                    (type) => type.checked && type.name === "Custom"
                  )
                    ? "block"
                    : "none",
                }}
              />
            </div>
            <div className="button-group">
              <button onClick={handlePrevious}>Previous</button>
              <button onClick={handleNextClick}>Next</button>
            </div>
          </div>
        </div>

        {showCustomPopup && (
          <div className="popup">
            <div className="popup-header">
              Please Fill the Custom Checkbox
              <span className="close" onClick={() => setShowCustomPopup(false)}>
                &times;
              </span>
            </div>
            <div className="popup-body">
              You haven't filled the custom checkbox. Please fill it to proceed.
            </div>
          </div>
        )}

        {showNoSelectionPopup && (
          <div className="popup">
            <div className="popup-header">
              Please Select at Least One Page/Screen Name
              <span
                className="close"
                onClick={() => setShowNoSelectionPopup(false)}
              >
                &times;
              </span>
            </div>
            <div className="popup-body">
              You haven't selected any page/screen names. Please choose at least
              one to proceed.
            </div>
          </div>
        )}
      </div>
    );
  };
  const SignUpOptionsSelection = ({ handleNext, handlePrevious }) => {
    const [signUpOptions, setSignUpOptions] = useState([
      { id: 1, name: "Email Sign-Up", checked: false, value: 1000 },
      { id: 2, name: "Social Media Sign-Up", checked: false, value: 1000 },
      { id: 3, name: "Phone OTP", checked: false, value: 1000 },
      { id: 4, name: "Face Detection", checked: false, value: 1000 },
      { id: 5, name: "Guest User", checked: false, value: 1000 },

      // Define other sign-up options here...
    ]);

    const [showPopup, setShowPopup] = useState(false); // State for popup visibility

    const handleCheckboxChange = (id) => {
      setSignUpOptions((prevOptions) =>
        prevOptions.map((option) =>
          option.id === id ? { ...option, checked: !option.checked } : option
        )
      );
    };

    useEffect(() => {
      localStorage.setItem("signUpOptions", JSON.stringify(signUpOptions));
    }, [signUpOptions]);

    const handleNextClick = () => {
      const selectedOptions = signUpOptions.filter((option) => option.checked);

      if (selectedOptions.length > 0) {
        handleNext(selectedOptions);
      } else {
        setShowPopup(true); // Show popup if no selections are made
      }
    };

    return (
      <div className="box-container">
        <div className="hello">
          <div className="content">
            <h2>
              How would you like your <br />
              Users toSign-up/Log-in <br />
              to your App?
            </h2>
            <div className="spacing">
              <ul className="ks-cboxtags">
                {signUpOptions.map((option) => (
                  <li key={option.id}>
                    <input
                      type="checkbox"
                      id={`checkbox${option.id}`}
                      checked={option.checked}
                      onChange={() => handleCheckboxChange(option.id)}
                    />
                    <label htmlFor={`checkbox${option.id}`}>
                      {option.name}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            <div className="button-group">
              <button onClick={handlePrevious}>Previous</button>
              <button onClick={handleNextClick}>Next</button>
            </div>
          </div>
        </div>

        {showPopup && ( // Conditionally render popup based on state
          <div className="popup">
            <div className="popup-header">
              Please Select at Least One Sign-Up Option
              <span className="close" onClick={() => setShowPopup(false)}>
                &times;
              </span>
            </div>
            <div className="popup-body">
              You haven't selected any sign-up options. Please choose at least
              one to proceed.
            </div>
          </div>
        )}
      </div>
    );
  };
  const FeaturesSelection = ({ handleNext, handlePrevious }) => {
    const [featuresOptions, setFeaturesOptions] = useState([
      { id: 1, name: "Maps and Geo-Location", checked: false },
      { id: 2, name: "In-App Payment", checked: false, value: 1000 },
      {
        id: 3,
        name: "Payment Gateway Integration",
        checked: false,
        value: 1000,
      },
      { id: 4, name: "Social Media", checked: false, value: 1000 },
      { id: 5, name: "IOT", checked: false, value: 1000 },
      { id: 6, name: "Push Notification", checked: false, value: 1000 },
      {
        id: 7,
        name: "Multimedia playing & Uploading",
        checked: false,
        value: 1000,
      },
      { id: 8, name: "Offline data and sync", checked: false, value: 1000 },
      {
        id: 9,
        name: "Chat/peer to peer communication",
        checked: false,
        value: 1000,
      },
      { id: 10, name: "Domain", checked: false, value: 1000 },
      { id: 11, name: "Hosting", checked: false, value: 1000 },
      // Define other feature options here...
    ]);

    const [showPopup, setShowPopup] = useState(false); // State for popup visibility

    const handleCheckboxChange = (id) => {
      setFeaturesOptions((prevOptions) =>
        prevOptions.map((option) =>
          option.id === id ? { ...option, checked: !option.checked } : option
        )
      );
    };

    useEffect(() => {
      localStorage.setItem("featuresOptions", JSON.stringify(featuresOptions));
    }, [featuresOptions]);

    const handleNextClick = () => {
      const selectedOptions = featuresOptions.filter(
        (option) => option.checked
      );

      if (selectedOptions.length > 0) {
        const selectedFeatures = selectedOptions.filter(
          (option) => option.value !== undefined
        );
        const featuresSubtotal = selectedFeatures.reduce(
          (total, feature) => total + feature.value * (feature.checked ? 1 : 0),
          0
        );
        handleNext(selectedFeatures, featuresSubtotal);
      } else {
        setShowPopup(true); // Show popup if no selections are made
      }
    };

    return (
      <div className="box-container">
        <div className="hello">
          <div className="content">
            <h2>
              Which features/functionalities
              <br /> would you like to incorporate
              <br /> in your App/Website?
            </h2>
            <div className="spacing">
              <ul className="ks-cboxtags">
                {featuresOptions.map((option) => (
                  <li key={option.id}>
                    <input
                      type="checkbox"
                      id={`checkbox${option.id}`}
                      checked={option.checked}
                      onChange={() => handleCheckboxChange(option.id)}
                    />
                    <label htmlFor={`checkbox${option.id}`}>
                      {option.name}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            <div className="button-group">
              <button onClick={handlePrevious}>Previous</button>
              <button onClick={handleNextClick}>Next</button>
            </div>
          </div>
        </div>

        {showPopup && ( // Conditionally render popup based on state
          <div className="popup">
            <div className="popup-header">
              Please Select at Least One Feature/Functionality
              <span className="close" onClick={() => setShowPopup(false)}>
                &times;
              </span>
            </div>
            <div className="popup-body">
              You haven't selected any feature/functionalities. Please choose at
              least one to proceed.
            </div>
          </div>
        )}
      </div>
    );
  };
  const AdminPanelSelection = ({ handleNext, handlePrevious }) => {
    const [adminPanelOptions, setAdminPanelOptions] = useState([
      { id: 1, name: "Yes", checked: false, value: 1000 },
      { id: 2, name: "No", checked: false, value: 0 },
    ]);

    const [showPopup, setShowPopup] = useState(false); // State for popup visibility

    const handleCheckboxChange = (id) => {
      setAdminPanelOptions((prevOptions) =>
        prevOptions.map((option) => ({
          ...option,
          checked: option.id === id ? !option.checked : false,
        }))
      );
    };

    useEffect(() => {
      localStorage.setItem(
        "adminPanelOptions",
        JSON.stringify(adminPanelOptions)
      );
    }, [adminPanelOptions]);

    const handleNextClick = () => {
      const selectedOption = adminPanelOptions.find((option) => option.checked);

      if (selectedOption) {
        handleNext([selectedOption]);
      } else {
        setShowPopup(true); // Show popup if no selections are made
      }
    };

    return (
      <div className="box-container">
        <div className="hello">
          <div className="content">
            <h2>
              Do you need an Admin <br />
              panel for your app/website?
            </h2>
            <div className="spacing">
              <ul className="ks-cboxtags">
                {adminPanelOptions.map((option) => (
                  <li key={option.id}>
                    <input
                      type="checkbox"
                      id={`checkbox${option.id}`}
                      checked={option.checked}
                      onChange={() => handleCheckboxChange(option.id)}
                    />
                    <label htmlFor={`checkbox${option.id}`}>
                      {option.name}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            <div className="button-group">
              <button onClick={handlePrevious}>Previous</button>
              <button onClick={handleNextClick}>Next</button>
            </div>
          </div>
        </div>

        {showPopup && ( // Conditionally render popup based on state
          <div className="popup">
            <div className="popup-header">
              Please Select Admin Panel Option
              <span className="close" onClick={() => setShowPopup(false)}>
                &times;
              </span>
            </div>
            <div className="popup-body">
              You haven't selected any admin panel option. Please choose one to
              proceed.
            </div>
          </div>
        )}
      </div>
    );
  };

  const DesignLogoSelection = ({ handleNext, handlePrevious }) => {
    const [designLogoOptions, setDesignLogoOptions] = useState([
      { id: 1, name: "Yes", checked: false, value: 1000 },
      { id: 2, name: "No", checked: false, value: 0 },
    ]);

    const [showPopup, setShowPopup] = useState(false); // State for popup visibility

    const handleCheckboxChange = (id) => {
      setDesignLogoOptions((prevOptions) =>
        prevOptions.map((option) => ({
          ...option,
          checked: option.id === id ? !option.checked : false,
        }))
      );
    };

    useEffect(() => {
      localStorage.setItem(
        "designLogoOptions",
        JSON.stringify(designLogoOptions)
      );
    }, [designLogoOptions]);

    const handleNextClick = () => {
      const selectedOption = designLogoOptions.find((option) => option.checked);

      if (selectedOption) {
        handleNext([selectedOption]);
      } else {
        setShowPopup(true); // Show popup if no selections are made
      }
    };

    return (
      <div className="box-container">
        <div className="hello">
          <div className="content">
            <h2>
              Do you need Design/Logo for <br />
              your app/website?
            </h2>
            <div className="spacing">
              <ul className="ks-cboxtags">
                {designLogoOptions.map((option) => (
                  <li key={option.id}>
                    <input
                      type="checkbox"
                      id={`checkbox${option.id}`}
                      checked={option.checked}
                      onChange={() => handleCheckboxChange(option.id)}
                    />
                    <label htmlFor={`checkbox${option.id}`}>
                      {option.name}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            <div className="button-group">
              <button onClick={handlePrevious}>Previous</button>
              <button onClick={handleNextClick}>Next</button>
            </div>
          </div>
        </div>

        {showPopup && ( // Conditionally render popup based on state
          <div className="popup">
            <div className="popup-header">
              Please Select Design/Logo Option
              <span className="close" onClick={() => setShowPopup(false)}>
                &times;
              </span>
            </div>
            <div className="popup-body">
              You haven't selected any design/logo option. Please choose one to
              proceed.
            </div>
          </div>
        )}
      </div>
    );
  };

  const ContactForm = ({
    selectedPlatforms,
    selectedWebsiteTypes,
    selectedNumberOfPages,
    selectedPages,
    selectedLoginOptions,
    selectedFeatures,
    selectedAdminPanel,
    selectedDesignLogo,
  }) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });

    const platformSubtotal = selectedPlatforms.reduce(
      (total, platform) => total + platform.value * (platform.checked ? 1 : 0),
      0
    );

    // Calculate subtotal for selected website types
    const websiteTypesSubtotal = selectedWebsiteTypes.reduce(
      (total, websiteType) =>
        total + websiteType.value * (websiteType.checked ? 1 : 0),
      0
    );
    const pagesScreenNamesSubtotal = selectedPages.reduce((total, page) => {
      if (page.name === "Custom") {
        return total + (page.checked ? page.value : 0);
      }
      return total + page.value * (page.checked ? 1 : 0);
    }, 0);

    // Calculate subtotal for selected pages/screen names
    const pages = selectedPages.reduce(
      (total, page) => total + page.value * (page.checked ? 1 : 0),
      0
    );

    // Calculate subtotal for selected sign-up options
    const signUpOptionsSubtotal = selectedLoginOptions.reduce(
      (total, option) => total + option.value * (option.checked ? 1 : 0),
      0
    );

    // Calculate subtotal for selected features/functionalities
    const featuresSubtotal = selectedFeatures.reduce((total, feature) => {
      if (feature.name === "Custom") {
        return total + (feature.checked ? feature.value : 0);
      }
      return total + feature.value * (feature.checked ? 1 : 0);
    }, 0);

    // Calculate subtotal for selected admin panel options
    const adminPanelSubtotal = selectedAdminPanel.reduce(
      (total, option) => total + option.value * (option.checked ? 1 : 0),
      0
    );

    // Calculate subtotal for selected design/logo options
    const designLogoSubtotal = selectedDesignLogo.reduce(
      (total, option) => total + option.value * (option.checked ? 1 : 0),
      0
    );

    // Calculate total
    const total =
      platformSubtotal +
      websiteTypesSubtotal +
      pages +
      pagesScreenNamesSubtotal +
      signUpOptionsSubtotal +
      featuresSubtotal +
      adminPanelSubtotal +
      designLogoSubtotal;

    const [formData, setFormData] = useState({
      name: "",
      email: "",
      message: "",
      date: formattedDate,
      platforms: selectedPlatforms.map((platform) => platform.name).join(", "),
      webtype: selectedWebsiteTypes.map((webtype) => webtype.name).join(", "),
      pagesf: selectedNumberOfPages.label,
      pagesName: selectedPages.map((pagesName) => pagesName.name).join(", "),
      loginOption: selectedLoginOptions
        .map((loginOption) => loginOption.name)
        .join(", "),
      features: selectedFeatures.map((features) => features.name).join(", "),
      admin1: selectedAdminPanel.map((admin1) => admin1.name).join(", "),
      designl: selectedDesignLogo.map((designl) => designl.name).join(", "),
      pamount: platformSubtotal,
      wamount: websiteTypesSubtotal,
      ppamount: pages,
      namount: pagesScreenNamesSubtotal,
      lamount: signUpOptionsSubtotal,
      famount: featuresSubtotal,
      aamount: adminPanelSubtotal,
      damount: designLogoSubtotal,
      tamount: total,
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post(
          "https://incronix.com/config.php",
          formData
        );

        alert(response.data);
        handleNext();
        setFormData({
          name: "",
          email: "",
          message: "",
          date: formattedDate,
          ...initialSelectedData(),
        });
      } catch (error) {
        console.error(error);
        alert("An error occurred. Please try again.");
      }
    };

    // Function to get initial selected data
    const initialSelectedData = () => ({
      platforms: selectedPlatforms.map((platform) => platform.name).join(", "),
      webtype: selectedWebsiteTypes.map((webtype) => webtype.name).join(", "),
      pagesf: selectedNumberOfPages.label,
      pagesName: selectedPages.map((pagesName) => pagesName.name).join(", "),
      loginOption: selectedLoginOptions
        .map((loginOption) => loginOption.name)
        .join(", "),
      features: selectedFeatures.map((features) => features.name).join(", "),
      admin1: selectedAdminPanel.map((admin1) => admin1.name).join(", "),
      designl: selectedDesignLogo.map((designl) => designl.name).join(", "),
      pamount: platformSubtotal,
      wamount: websiteTypesSubtotal,
      ppamount: pages,
      namount: pagesScreenNamesSubtotal,
      lamount: signUpOptionsSubtotal,
      famount: featuresSubtotal,
      aamount: adminPanelSubtotal,
      damount: designLogoSubtotal,
      tamount: total,
    });

    return (
      <section className="text-gray-600 body-font relative">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-12">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              Contact Us
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              "Effortless Communication: Reach Out to Us Today for Any
              Questions, Concerns, or Inquiries"
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="lg:w-1/2 md:w-2/3 mx-auto">
              <div className="flex flex-wrap -m-2">
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="name"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="email"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="p-2 w-full">
                  <div className="relative">
                    <label
                      htmlFor="message"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="p-2 w-full">
                  <div className="relative">
                    <label
                      htmlFor="date"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Date
                    </label>
                    <input
                      type="text"
                      id="date"
                      name="date"
                      className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      value={formData.date}
                      readOnly // To prevent user input
                    />
                  </div>
                </div>
                <div className="p-2 w-full">
                  <button
                    type="submit"
                    className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const ReviewAndSubmit = () => {
    const [currentComponent, setCurrentComponent] = useState("review");
    // Calculate subtotal for selected platforms
    const platformSubtotal = selectedPlatforms.reduce(
      (total, platform) => total + platform.value * (platform.checked ? 1 : 0),
      0
    );

    // Calculate subtotal for selected website types
    const websiteTypesSubtotal = selectedWebsiteTypes.reduce(
      (total, websiteType) =>
        total + websiteType.value * (websiteType.checked ? 1 : 0),
      0
    );

    // Calculate subtotal for selected pages/screen names
    const pagesScreenNamesSubtotal = selectedPages.reduce((total, page) => {
      if (page.name === "Custom") {
        return total + (page.checked ? page.value : 0);
      }
      return total + page.value * (page.checked ? 1 : 0);
    }, 0);

    // Calculate subtotal for selected pages/screen names
    const pages = selectedPages.reduce(
      (total, page) => total + page.value * (page.checked ? 1 : 0),
      0
    );

    // Calculate subtotal for selected sign-up options
    const signUpOptionsSubtotal = selectedLoginOptions.reduce(
      (total, option) => total + option.value * (option.checked ? 1 : 0),
      0
    );

    // Calculate subtotal for selected features/functionalities
    const featuresSubtotal = selectedFeatures.reduce((total, feature) => {
      if (feature.name === "Custom") {
        return total + (feature.checked ? feature.value : 0);
      }
      return total + feature.value * (feature.checked ? 1 : 0);
    }, 0);

    // Calculate subtotal for selected admin panel options
    const adminPanelSubtotal = selectedAdminPanel.reduce(
      (total, option) => total + option.value * (option.checked ? 1 : 0),
      0
    );

    // Calculate subtotal for selected design/logo options
    const designLogoSubtotal = selectedDesignLogo.reduce(
      (total, option) => total + option.value * (option.checked ? 1 : 0),
      0
    );

    // Calculate total
    const total =
      platformSubtotal +
      websiteTypesSubtotal +
      pages +
      pagesScreenNamesSubtotal +
      signUpOptionsSubtotal +
      featuresSubtotal +
      adminPanelSubtotal +
      designLogoSubtotal;

    const handlePrevious = () => {
      setCurrentComponent("platformSelection");
    };

    const handlePreviousAndReload = () => {
      handlePrevious(); // This function might handle any additional logic for going to the previous step
      window.location.reload(); // Reloads the entire page
    };

    return (
      <div style={{ textAlign: "center" }}>
        <div className="box-container">
          <div className="hello">
            <div className="content">
              <h2>Quotation</h2>
            </div>
            <div className="tbl-content">
              <div className="tbl-header">
                <table cellPadding="0" cellSpacing="0" border="0">
                  <thead>
                    <tr align="center">
                      <td>
                        <b>Options</b>
                      </td>
                      <td className="centerHeadling1">
                        <b>Selected Options</b>
                      </td>
                      <td>
                        <b>Amount</b>
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div className="tbl-content">
              <table cellPadding="0" cellSpacing="0" border="0">
                <tbody>
                  <tr>
                    <td>
                      <i className="fas fa-layer-group"></i>
                      <b> Selected Platforms:</b>
                    </td>
                    <td>
                      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                        {selectedPlatforms.map((p) => (
                          <li>
                            &bull; {p.name} - ₹{p.value}
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li>₹{platformSubtotal}</li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <i className="fas fa-desktop"></i>
                      <b> Selected Website Types:</b>
                    </td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: `
                       <ul style="list-style-type: none; padding-left: 0;">
                       ${selectedWebsiteTypes
                         .map((t) => `<li>&bull; ${t.name}</li>`)
                         .join("")}
                      </ul>
                       `,
                      }}
                    />
                    <td>₹{websiteTypesSubtotal}</td>
                  </tr>

                  <tr>
                    <td>
                      <i className="fas fa-file-alt"></i>
                      <b> Selected Number of Pages:</b>
                    </td>
                    <td>&bull; {selectedNumberOfPages.label}</td>
                    <td>₹{pages}</td>
                  </tr>
                  <tr>
                    <td>
                      <i className="fas fa-file"></i>
                      <b> Selected Pages/Screen Names:</b>
                    </td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: `
                      <ul style="list-style-type: none; padding-left: 0;">
                        ${selectedPages
                          .map((page) => `<li>&bull; ${page.name}</li>`)
                          .join("")}
                      </ul>
                        `,
                      }}
                    />

                    <td>₹{pagesScreenNamesSubtotal}</td>
                  </tr>
                  <tr>
                    <td>
                      <i className="fas fa-user"></i>
                      <b> Selected Sign-Up Options:</b>
                    </td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: `
                         <ul style="list-style-type: none; padding-left: 0;">
                            ${selectedLoginOptions
                              .map((option) => `<li>&bull; ${option.name}</li>`)
                              .join("")}
                         </ul>
                         `,
                      }}
                    />

                    <td>₹{signUpOptionsSubtotal}</td>
                  </tr>
                  <tr>
                    <td>
                      <i className="fas fa-cogs"></i>
                      <b> Selected Features/Functionalities:</b>
                    </td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: `
                        <ul style="list-style-type: none; padding-left: 0;">
                           ${selectedFeatures
                             .map(
                               (feature) => `<li>&bull; ${feature.name}</li>`
                             )
                             .join("")}
                        </ul>
                         `,
                      }}
                    />

                    <td>₹{featuresSubtotal}</td>
                  </tr>
                  <tr>
                    <td>
                      <i className="fas fa-user-lock"></i>
                      <b> Need Admin Panel:</b>
                    </td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: `
                        <ul style="list-style-type: none; padding-left: 0;">
                           ${selectedAdminPanel
                             .map((option) => `<li>&bull; ${option.name}</li>`)
                             .join("")}
                       </ul>
                         `,
                      }}
                    />

                    <td>₹{adminPanelSubtotal}</td>
                  </tr>
                  <tr>
                    <td>
                      <i className="fas fa-paint-brush"></i>
                      <b> Need Design/Logo:</b>
                    </td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: `
                          <ul style="list-style-type: none; padding-left: 0;">
                            ${selectedDesignLogo
                              .map((option) => `<li>&bull; ${option.name}</li>`)
                              .join("")}
                          </ul>
                               `,
                      }}
                    />
                    <td>₹{designLogoSubtotal}</td>
                  </tr>
                  <tr>
                    <td>
                      <i className="fas fa-coins"></i>
                      <b> Total:</b>
                    </td>
                    <td></td>
                    <td>₹{total}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="button-group">
              <button onClick={handlePreviousAndReload}>Previous</button>
            </div>
            <br />
          </div>
        </div>
      </div>
    );
  };

  // Your existing code here...

  return (
    <div className="box-container">
      <div className="hello">
        <div className="content">
          <div className="spacing">
            {step === 1 && <PlatformSelection handleNext={handleNext} />}
            {step === 2 && (
              <WebsiteTypeSelection
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            )}
            {step === 3 && (
              <NumberOfPagesSelection
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            )}
            {step === 4 && (
              <PagesScreenNameSelection
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            )}
            {step === 5 && (
              <SignUpOptionsSelection
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            )}
            {step === 6 && (
              <FeaturesSelection
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            )}
            {step === 7 && (
              <AdminPanelSelection
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            )}
            {step === 8 && (
              <DesignLogoSelection
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            )}
            {step === 9 && (
              <ContactForm
                selectedPlatforms={selectedPlatforms}
                selectedWebsiteTypes={selectedWebsiteTypes}
                selectedNumberOfPages={selectedNumberOfPages}
                selectedPages={selectedPages}
                selectedLoginOptions={selectedLoginOptions}
                selectedFeatures={selectedFeatures}
                selectedAdminPanel={selectedAdminPanel}
                selectedDesignLogo={selectedDesignLogo}
                handlePrevious={handlePrevious}
                handleNext={handleNext}
              />
            )}
            {step === 10 && <ReviewAndSubmit handlePrevious={handlePrevious} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calculation;
