import React, { useState, useEffect } from "react";
import "./QuotationData.css";
import "./style.css";

function ContactDB() {
  const [contactData, setContactData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch("https://incronix.com/contact.php")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch");
        }
        return response.json();
      })
      .then((data) => {
        // Assuming data is an array
        setContactData(data); // Set data as fetched
        setSortedData(data); // Initially set sorted data same as fetched data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error (e.g., set error state)
      });
  };

  const sortByName = () => {
    const sorted = [...contactData].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setSortedData(sorted);
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filtered = contactData.filter((contact) =>
      contact.name.toLowerCase().includes(term.toLowerCase())
    );
    setSortedData(filtered);
  };

  return (
    <div className="page-container">
      <div className="quotation-container">
        <h2>Contact</h2>
        <div>
          <input
            type="text"
            placeholder="Search by Name"
            value={searchTerm}
            onChange={handleSearch}
          />
          <button onClick={sortByName}>Sort by Name</button>
        </div>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Subject</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {sortedData.map((contact, index) => (
                <tr key={index}>
                  <td>{contact.name}</td>
                  <td>{contact.email}</td>
                  <td>{contact.phone}</td>
                  <td>{contact.subject}</td>
                  <td>{contact.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ContactDB;
