import shweta from "../Certificates/30302240524.png";
import siddhesh from "../Certificates/50102240524.png";
import vikram from "../Certificates/50202240524.png";
import avadhut from "../Certificates/10101240624.png";
import shlok from "../Certificates/10201240624.png";
import kiran from "../Certificates/10301240624.png";
import pritam from "../Certificates/20101240624.png";
import shubham from "../Certificates/20201240624.png";
import prerna from "../Certificates/20301240624.png";
import vaibhao from "../Certificates/20401240624.png";
import supriya from "../Certificates/20502240724.png";
import nimish from "../Certificates/30101240624.png";
import sara from "../Certificates/30201240624.png";
import aditya from "../Certificates/30407240824.png";

const certificates = {
  10101240624: { src: avadhut, name: "Avadhut Tehare" },
  10201240624: { src: shlok, name: "Shlok Das" },
  10301240624: { src: kiran, name: "Kiran Kinge" },
  20101240624: { src: pritam, name: "Pritam Bhure" },
  20201240624: { src: shubham, name: "Shubham Biswas" },
  20301240624: { src: prerna, name: "Prerna Bharti" },
  20401240624: { src: vaibhao, name: "Vaibhao Bhendarkar" },
  20502240724: { src: supriya, name: "Supriya Shinde" },
  30101240624: { src: nimish, name: "Nimish Jadhav" },
  30201240624: { src: sara, name: "Sara Patil" },
  30302240524: { src: shweta, name: "Shweta Pandhare" },
  50102240524: { src: siddhesh, name: "Siddhesh Somvanshi" },
  50202240524: { src: vikram, name: "Vikram Kumawat" },
  30407240824: { src: aditya, name: "Aditya Dabhade" },

  // Add more mappings as needed
};

export default certificates;
