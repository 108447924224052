import React, { cloneElement, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import Mail from "../Components/Mail";
import AOS from "aos";
import "aos/dist/aos.css";
import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 120,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <>
      <link href="assets/bootstrap/bootstrap.min.css" rel="stylesheet" />
      <link rel="stylesheet" href="./assets/js/bootstrap.min.js" />

      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
      />

      <link href="assets/css/style.css" rel="stylesheet" type="text/css" />
      <link
        href="assets/css/mediaqueries.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="assets/css/owl.carousel.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="assets/css/owl.theme.default.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <div className="sub-banner-section">
        <Header />
        {/* SUB BANNER SECTION */}
        <section className="banner-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-md-left text-center">
                <div className="banner-section-content">
                  <h1 data-aos="fade-up">About Us</h1>
                  <p data-aos="fade-right">
                    At INCRONIX, our tailored solutions serve as your roadmap to
                    achievement, carefully tailored to address your individual
                    requirements and propel you toward greatness.
                  </p>
                  <div className="btn_wrapper">
                    <span>Home </span>
                    <i
                      className="fa-solid fa-angles-right"
                      aria-hidden="true"
                    />
                    <span className="sub_span"> About</span>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                data-aos="fade-left"
              >
                <div className="banner-section-image">
                  <figure className="mb-0">
                    <img
                      src="./assets/images/biglogo6.webp"
                      alt=""
                      className="img-fluid"
                      style={{
                        marginTop: "-20px",
                        maxWidth: "100%",
                        marginLeft: "auto",
                        justifyContent: "center",
                      }}
                    />
                  </figure>
                </div>
              </div>
            </div>
            <figure className="sub-banner_yellow_icon mb-0">
              <img
                src="./assets/images/sub_banner_yellow_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_va_icon mb-0">
              <img
                src="./assets/images/sub_banner_va_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_bottom_white_icon mb-0">
              <img
                src="./assets/images/sub_banner_bottom_white_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_right_top_icon mb-0">
              <img
                src="./assets/images/sub_banner_right_top_transparent_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
            <figure className="sub-banner_right_bottom_icon mb-0">
              <img
                src="./assets/images/sub_banner_right_bottom_icon.png"
                alt=""
                className="img-fluid"
              />
            </figure>
          </div>
        </section>
      </div>
      {/* ABOUT US SECTION */}
      <section className="about_section">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div
                className="about-content"
                style={{ Width: "600px", margin: "0 auto" }}
              >
                <h6>ABOUT US</h6>
                <h2>
                  Our Business is to Making Your life Easier & Comfortable
                </h2>
                <p>
                  At INCRONIX, our motivation springs from a deep-seated
                  enthusiasm for technology and a dedication to providing
                  groundbreaking software solutions that enable businesses to
                  flourish in the digital era. Our team of dedicated developers,
                  designers, and technology experts is eager to breathe life
                  into your vision. We're here to close the divide between your
                  concepts and their tangible effects, assisting you in
                  achieving your objectives.
                </p>
              </div>
            </div>
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="about_image">
                <figure className="mb-0">
                  <img
                    src="./assets/images/website4.gif"
                    alt=""
                    className="img-fluid"
                    style={{ maxWidth: "100%" }}
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* OUR STATISTICS SECTION */}
      <section className="in_touch_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="in_touch-content">
                <h6>GET IN TOUCH</h6>
                <h2>Our Contact Information</h2>
                <p>
                  We’d love to know more about your project. Contact us at
                  hr@incronix.com or simply fill up the form.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center" data-aos="fade-up">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="in_touch_box">
                <div className="in_touch_box_image">
                  <figure className="mb-0">
                    <img
                      src="./assets/images/contact_location_image.png"
                      alt=""
                      className="img-fluid"
                    />
                  </figure>
                </div>
                <div className="in_touch_box_content" style={{ padding: "px" }}>
                  <h4>Location</h4>
                  <p className="mb-0">Aundh, Pune</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="in_touch_box">
                <div className="in_touch_box_image">
                  <figure className="mb-0">
                    <img
                      src="./assets/images/contact_mail_image.png"
                      alt=""
                      className="img-fluid"
                    />
                  </figure>
                </div>
                <div className="in_touch_box_content" style={{ padding: "px" }}>
                  <h4>Email</h4>
                  <p className="mb-0">hr@incronix.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* SEND MESSAGE SECTION */}
      <Mail />

      {/* VIRTUAL EMPLOYEE SECTION */}
      <section class="virtual_employee-section">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-6 col-md-6 col-sm-12 col-xs-12"
              style={{ textAlign: "center" }}
            >
              <div class="virtual_employee_image">
                <figure class="mb-0">
                  <img
                    src="./assets/images/webservice4.jpeg"
                    alt=""
                    class="img-fluid"
                    style={{ marginLeft: "10px" }}
                  />
                </figure>
              </div>
              <figure class="virtual_employee_yellow_icon mb-0">
                <img
                  src="./assets/images/virtual_employee_yellow_icon.png"
                  alt=""
                  class="img-fluid"
                />
              </figure>
            </div>
            <div
              class="col-lg-6 col-md-6 col-sm-12 col-xs-12"
              data-aos="fade-right"
            >
              <div class="virtual_employee_content">
                <h2>Elevating Your Business Beyond Limits</h2>
                <p>
                  Customer satisfaction is our top priority at INCRONIX. We are
                  committed to providing exceptional service and ensuring that
                  every interaction leaves our customers fully satisfied. Your
                  happiness is what drives us forward, and we strive to exceed
                  your expectations in every way possible.
                </p>
              </div>
            </div>
          </div>
          <figure class="virtual_employee_left_icon mb-0">
            <img
              src="./assets/images/virtual_employee_left_icon.png"
              alt=""
              class="img-fluid"
            />
          </figure>
          <figure class="virtual_employee_right_top_icon mb-0">
            <img
              src="./assets/images/virtual_employee_right_top_icon.png"
              alt=""
              class="img-fluid"
            />
          </figure>
          <figure class="virtual_employee_right_bottom_icon mb-0">
            <img
              src="./assets/images/virtual_employee_right_bottom_icon.png"
              alt=""
              class="img-fluid"
            />
          </figure>
        </div>
      </section>

      {/* FOOTER SECTION */}
      <Footer />

      {/* Latest compiled JavaScript */}
    </>
  );
};

export default About;
